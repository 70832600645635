console.log("ENV", import.meta.env);

const envVars = import.meta.env;

const localConfig = {
  apiUrl: "http://localhost:5000",
};

const prodConfig = {
  apiUrl: "https://advize-production-api-980910018979.us-central1.run.app",
};

let configToUse = localConfig;

if (envVars.VITE_ADVIZE_ENV === "prod" || envVars.VITE_ADVIZE_ENV === "staging") {
  configToUse = prodConfig;
}

export const config = {
  ...configToUse,
  isEmulate: envVars.MODE === "emulate",
  isProduction: envVars.VITE_ADVIZE_ENV === "prod",
  cacheInvalidator: 10, // increase this to force user client to refetch data from server
  siteUrl: window.location.origin,
  supportEmailLink:
    "mailto:hello@advizehub.com?subject=Support%20Request%20-%20Advize",
  termsUrl: "https://www.advizehub.com/terms-conditions",
  privacyUrl: "https://www.advizehub.com/privacy-policy",
  slackInviteLink:
    "https://join.slack.com/t/advize-talk/shared_invite/zt-2slz1tt2v-yJf7N1Kt04kTmops5H0RYg",
  authRedirectUrl: `${window.location.origin}/auth/redirect`,
};
