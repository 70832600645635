import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/carousel/styles.css";
import "react-virtualized/styles.css";

import "./styles/global.css";
import "./styles/uiComponents.css";

import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { useTheme } from "./styles/theme.ts";
import { router } from "./routing.tsx";
import "./firebase/firebase.ts";

function App() {
  const theme = useTheme();
  return (
    <MantineProvider theme={theme}>
      <RouterProvider router={router} />
    </MantineProvider>
  );
}

export default App;
