import { forwardRef, useCallback } from "react";
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from "@mantine/core";
import { Link } from "./Link";

export interface ButtonProps
  extends MantineButtonProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color" | "style"> {
  onClick?: (event: any) => void;
  to?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { className, onClick, to, ...rest } = props;

    const renderButton = useCallback(
      (extraProps?: any) => {
        return (
          <MantineButton
            className={`custom-button ${className}`}
            {...rest}
            {...extraProps}
            ref={ref}
            onClick={onClick}
          />
        );
      },
      [className, onClick, ref, rest]
    );

    if (to) {
      return <Link to={to}>{renderButton()}</Link>;
    }
    return renderButton({ color: "primary" });
  }
);

Button.displayName = "Button";
