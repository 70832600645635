import { useEffect } from "react";
import { config } from "../../config";
import { Loader, Stack, Title, Text } from "@mantine/core";
import { Link } from "../ui/Link";

export function RedirectPage() {
  useEffect(() => {
    window.location.href = config.slackInviteLink;
  }, []);

  return (
    <Stack h="100vh" justify="center" align="center">
      <Loader size="xl" color="primary.7" />
      <Title order={2} c="text.6" ta="center">
        Redirecting you to our <br/>Slack community...
      </Title>
      <Text c="text.4" size="sm">
        Having issues?{" "}
        <Link to={config.slackInviteLink} target="_blank">
          Click here to contact support.
        </Link>
      </Text>
    </Stack>
  );
}
