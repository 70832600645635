import {
  alpha,
  Title,
  Box,
  AspectRatio,
  Group,
  Stack,
  Skeleton,
  ActionIcon,
} from "@mantine/core";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useStyles } from "../styles/useStyles";
import {
  IconArrowLeft,
  IconArrowRight,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";
import {
  useIsLoggedIn,
  useIsRestrictedApp,
  useIsVideoPlaying,
} from "../store/selectors";
import { useStore } from "../store";
import { useDeviceType } from "../hooks";
import { Navigation } from "../services/Navigation";
import { Analytics } from "../services/Analytics";

export const AdvizerVideo = (props: {
  videoId: string;
  title: string | ReactNode;
  url: string;
  questionNumber?: number;
  isInBackground?: boolean;
  withControls?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  video?: any;
}) => {
  const {
    videoId,
    title,
    url,
    questionNumber,
    isInBackground,
    withControls,
    onClickNext,
    onClickPrevious,
    video,
  } = props;
  const { theme } = useStyles();
  const isVideoPlaying = useIsVideoPlaying(videoId);

  const setActiveVideoId = useStore((state) => state.videos.setActiveVideoId);
  const [isPlayHovered, setIsPlayHovered] = useState(false);
  const { isMobileDevice } = useDeviceType();
  const isLoggedIn = useIsLoggedIn();
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [secondsWatched, setSecondsWatched] = useState(0);
  const [previousPlayedSeconds, setPreviousPlayedSeconds] = useState(0);
  const lastReportedPercent = useRef(0);
  const [hasSeeked, setHasSeeked] = useState(false);
  const isRestrictedApp = useIsRestrictedApp();
  const incrementNumVideosPlayed = useStore(
    (state) => state.user.incrementNumVideosPlayed
  );

  // console.log({ title, isVideoPlaying, isInBackground, isMobileDevice });

  const resetVideo = useCallback(() => {
    console.log("resetting video");
    setPreviousPlayedSeconds(0);
    setHasSeeked(false);
    setSecondsWatched(0);
    setIsPlayHovered(false);
  }, []);

  useEffect(() => {
    if (isInBackground && isVideoPlaying) {
      setActiveVideoId(null);
    }
  }, [isInBackground]);

  function handlePlayVideo() {
    if (!isLoggedIn) {
      if (isRestrictedApp) {
        Navigation.navToLogin();
        Analytics.click({
          action: "Play Video > Not logged in",
        });
      }
    }

    setActiveVideoId(videoId);

    // if (useIsRestrictedApp()) {
    //   Navigation.navToLogin();
    // } else {
    //   setActiveVideoId(videoId);
    // }
  }

  return (
    <Stack
      gap={0}
      h="100%"
      w="100%"
      justify="flex-start"
      style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
      display={isInBackground ? "none" : "flex"}
    >
      <Group
        bg={alpha(theme.colors.primary[7], 1)}
        px="xs"
        py="xs"
        w="100%"
        justify="space-between"
        gap={4}
      >
        {onClickPrevious ? (
          <NextPrevButton onClick={onClickPrevious} hide={!withControls} />
        ) : null}
        <Title
          fz={20}
          flex={1}
          c={theme.colors.text[0]}
          ta="left"
          px={withControls ? 4 : 0}
          lineClamp={1}
        >
          {questionNumber && (
            <span style={{ color: theme.colors.primary[3], paddingRight: 8 }}>
              Q{questionNumber}
            </span>
          )}
          {title}
        </Title>
        {onClickNext ? (
          <NextPrevButton onClick={onClickNext} isRight hide={!withControls} />
        ) : null}
      </Group>
      <AspectRatio
        component="div"
        ratio={16 / 9}
        w="100%"
        pos="relative"
        style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
        bg="background.2"
        onMouseEnter={() => {
          setIsPlayHovered(true);
        }}
        onMouseLeave={() => {
          setIsPlayHovered(false);
        }}
      >
        {!videoId ? (
          <Skeleton h="100%" w="100%" animate />
        ) : (
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            playing={isVideoPlaying}
            light
            controls
            playsinline={true}
            volume={1}
            muted={!!isMobileDevice}
            fallback={<Box h="100%" w="100%" bg="background.4" />}
            style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
            playIcon={
              <Group
                bg={
                  isPlayHovered
                    ? alpha(theme.colors.primary[9], 0.9)
                    : alpha(theme.colors.primary[9], 0.5)
                }
                p={8}
                style={{ borderRadius: "50%" }}
              >
                <IconPlayerPlayFilled
                  color={
                    isPlayHovered ? theme.colors.text[0] : theme.colors.text[2]
                  }
                  size={40}
                />
              </Group>
            }
            onStart={() => {
              resetVideo();
              incrementNumVideosPlayed();
              Analytics.startVideo({
                video,
                videoDurationSeconds: durationInSeconds,
              });
            }}
            onProgress={(data) => {
              const roundedPercent = Math.floor((data.played * 100) / 5) * 5;
              let localHasSeeked = hasSeeked;

              if (Math.abs(data.playedSeconds - previousPlayedSeconds) > 1.5) {
                setHasSeeked(true);
                localHasSeeked = true;
                Analytics.seekVideo({
                  video,
                  videoSeekFromSeconds: previousPlayedSeconds,
                  videoSeekToSeconds: data.playedSeconds,
                  videoDurationSeconds: durationInSeconds,
                  videoHasSeeked: hasSeeked,
                });
              }

              setPreviousPlayedSeconds(data.playedSeconds);

              if (roundedPercent > lastReportedPercent.current) {
                lastReportedPercent.current = roundedPercent;
                setSecondsWatched(Math.floor(data.playedSeconds));
                Analytics.videoProgress({
                  video,
                  videoWatchedSeconds: Math.floor(data.playedSeconds),
                  videoWatchedPercent: roundedPercent,
                  videoDurationSeconds: durationInSeconds,
                  videoHasSeeked: localHasSeeked,
                });
              }
            }}
            onDuration={(d) => {
              setDurationInSeconds(d);
            }}
            onPlay={() => {
              setActiveVideoId(videoId);
              handlePlayVideo();
            }}
            onPause={() => {
              Analytics.pauseVideo({
                video,
                videoWatchedSeconds: secondsWatched,
                videoWatchedPercent: lastReportedPercent.current,
                videoDurationSeconds: durationInSeconds,
              });
            }}
            onClickPreview={() => {
              // if (!isLoggedIn) {
              //   Navigation.navToLogin();
              //   Analytics.click({
              //     action: "Play Video > Not logged in",
              //   });
              // } else {
              setActiveVideoId(videoId);
              // }
            }}
          />
        )}
      </AspectRatio>
    </Stack>
  );
};

function NextPrevButton(props: {
  onClick: () => void;
  isRight?: boolean;
  hide?: boolean;
}) {
  return (
    <Stack h={42}>
      <ActionIcon
        onClick={props.hide ? undefined : props.onClick}
        size={props.hide ? 10 : "xl"}
        radius="xl"
        color="primary.8"
        opacity={props.hide ? 0 : 1}
      >
        {props.isRight ? <IconArrowRight /> : <IconArrowLeft />}
      </ActionIcon>
    </Stack>
  );
}
