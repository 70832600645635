import { useCallback, useEffect } from "react";
import { Notifications } from "@mantine/notifications";

import { checkIfMatchesEmailFormat, shuffleArray } from "../../lib/helpers";
import { UiUpdates } from "./UiUpdates";
import { AdvizeApi } from "../../api/api";
import {
  useActiveUser,
  usePartners,
  useSetApiData,
} from "../../store/selectors";
import { Advizer } from "../../models/Advizer";
import { IAdvizeApiDataKeys } from "../../types/StoreTypes";
import { Auth } from "./Auth";
import { useStore } from "../../store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoogleAnalytics } from "./GoogleAnalytics";
import { DataListeners } from "./DataListeners";

export const LogicWrapper = ({ children }: { children: React.ReactNode }) => {
  const setApiData = useSetApiData();
  const { navToPath, setNavToPath } = useStore((state) => state.navigation);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { partnerSlug } = useParams();
  const partners = usePartners();
  const setActivePartner = useStore((state) => state.partner.setActivePartner);
  const setIsAppLoading = useStore((state) => state.setIsAppLoading);
  const activeUser = useActiveUser();
  const init = useCallback(async () => {
    const fetchAndSetData = async (
      apiCall: (config?: { noCache?: boolean }) => Promise<any>,
      key: IAdvizeApiDataKeys,
      transform?: (data: any) => any,
      shuffle?: boolean
    ) => {
      let items: any[] = [];
      try {
        const response = await apiCall();
        items = response?.data?.items || [];

        if (!items.length) {
          throw new Error("No items found");
        }
      } catch (e) {
        console.error(e);
        if (!items.length) {
          const response2 = await apiCall({ noCache: true });
          items = response2?.data?.items || [];
        }
      }

      if (shuffle) {
        items = shuffleArray(items);
      }
      setApiData({ key, data: transform ? items.map(transform) : items });
    };

    await Promise.all([
      fetchAndSetData(
        (config) =>
          AdvizeApi.queryAdvizersWithEverything({
            take: 1000,
            skip: 0,
            ...config,
          }),
        "advizers",
        (a: any) => new Advizer(a),
        true
      ),
      fetchAndSetData((config) => AdvizeApi.querySubjects(config), "subjects"),
      fetchAndSetData(
        (config) => AdvizeApi.queryJobFunctions(config),
        "jobFunctions"
      ),
      fetchAndSetData(
        (config) => AdvizeApi.queryIndustries(config),
        "industries"
      ),
      fetchAndSetData((config) => AdvizeApi.queryTraits(config), "traits"),
      fetchAndSetData(
        (config) => AdvizeApi.queryQuestions(config),
        "questions"
      ),
      fetchAndSetData((config) => AdvizeApi.queryPartners(config), "partners"),
    ]);
  }, [setApiData]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navToPath) {
      navigate(navToPath);
      setNavToPath(null);
    }
  }, [navToPath, setNavToPath, navigate]);

  useEffect(() => {
    if (partnerSlug && partners) {
      const partner = partners?.find((p) => p.slug === partnerSlug);
      setActivePartner(partner);
    }

    setIsAppLoading(false);
  }, [partnerSlug, partners, setActivePartner, setIsAppLoading]);

  useEffect(() => {
    if (partnerSlug) {
      if (partners) {
        const partner = partners.find((p) => p.slug === partnerSlug);
        setActivePartner(partner || null);
      }
      return;
    } else if (!activeUser) {
      setActivePartner(null);
    }

    if (partners && activeUser?.email) {
      const partner = partners?.find((p) =>
        checkIfMatchesEmailFormat({
          email: activeUser?.email || "",
          emailFormat: p.emailFormat,
        })
      );

      setActivePartner(partner || null);
    }
  }, [
    activeUser,
    activeUser?.email,
    partners,
    setActivePartner,
    pathname,
    partnerSlug,
  ]);

  useEffect(() => {}, []);

  return (
    <>
      <UiUpdates />
      <Auth />
      <Notifications />
      <GoogleAnalytics />
      <DataListeners />
      {children}
    </>
  );
};
