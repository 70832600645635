import { forwardRef, useState, useEffect, useRef, useCallback } from "react";
import {
  ActionIcon,
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";

type MantineTextInputValue = string;

export interface TextInputProps extends Omit<MantineTextInputProps, "value"> {
  value: MantineTextInputValue;
  defaultValue?: MantineTextInputValue;
  onDebouncedChange?: (value: MantineTextInputValue) => void;
  debounceDelay?: number;
  onClear?: () => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { onDebouncedChange, debounceDelay = 300, onClear, value, ...props },
    ref
  ) => {
    const [localValue, setLocalValue] = useState<MantineTextInputValue>(
      value || props.defaultValue || ""
    );
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     console.log("DEBOUNCED CHANGE", value);
    //     onDebouncedChange?.(value);
    //   }, debounceDelay);

    //   return () => clearTimeout(timer);
    // }, [value, onDebouncedChange, debounceDelay]);

    useEffect(() => {
      setLocalValue(value || "");
    }, [value]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setLocalValue(newValue);
        props.onChange?.(event);

        // Clear existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set new timeout
        timeoutRef.current = setTimeout(() => {
          onDebouncedChange?.(newValue);
        }, debounceDelay);
      },
      [onDebouncedChange, debounceDelay]
    );

    const handleClear = useCallback(() => {
      setLocalValue("");
      onClear?.();

      // Clear existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Trigger debounced change with empty string
      // timeoutRef.current = setTimeout(() => {
      //   onDebouncedChange?.("");
      // }, debounceDelay);
    }, [onClear]);

    return (
      <MantineTextInput
        {...props}
        value={localValue}
        onChange={handleChange}
        ref={ref}
        rightSection={
          value && onClear ? (
            <ActionIcon
              onClick={handleClear}
              variant="transparent"
              color="text.4"
            >
              <IconX />
            </ActionIcon>
          ) : null
        }
      />
    );
  }
);

TextInput.displayName = "TextInput";
