import { IAdvizerModel } from "../models/Advizer";
import { Button, Card, Group, Stack } from "@mantine/core";
import { AdvizerVideo } from "./AdvizerVideo";
import { useStore } from "../store";
import { useCallback, useMemo, useState } from "react";

export const AdvizerVideoSlideshow = (props: {
  classes?: Record<string, string>;
  advizer: IAdvizerModel;
  withNavButtons?: boolean;
}) => {
  const { advizer, withNavButtons = false } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId
  );

  const handleSlideChange = useCallback(
    (index: number) => {
      if (index > advizer.videos.length - 1) {
        index = 0;
      } else if (index < 0) {
        index = advizer.videos.length - 1;
      }
      setCurrentSlideIndex(index);
    },
    [advizer.videos.length]
  );

  const currentVideo = useMemo(() => {
    if (activeQuestionId) {
      return advizer.videos.find(
        (video: any) => video.question?.id === activeQuestionId
      );
    }
    return advizer.videos[currentSlideIndex];
  }, [activeQuestionId, advizer.videos, currentSlideIndex]);

  return (
    <Card padding="md" radius="md" bg="transparent" w="100%" h="100%" flex={1}>
      <Card.Section pos="relative">
        <Stack w="100%" h="100%">
          {advizer.videos.map((video: any, index: number) => {
            const isCurrentVideo = currentVideo?.id === video.id;
            return (
              <AdvizerVideo
                key={video.id}
                videoId={video.id}
                video={video}
                title={video.title || ""}
                url={video.url}
                questionNumber={video.questionNumber || index + 1}
                withControls={!activeQuestionId}
                isInBackground={!isCurrentVideo}
                onClickNext={() => {
                  handleSlideChange(index + 1);
                }}
                onClickPrevious={() => {
                  handleSlideChange(index - 1);
                }}
              />
            );
          })}
          {/* {currentVideo && (
            <AdvizerVideo
              videoId={currentVideo.id}
              video={currentVideo}
              title={currentVideo.title}
              url={currentVideo.url}
              questionNumber={
                currentVideo.questionNumber || currentSlideIndex + 1
              }
              withControls={!activeQuestionId}
              onClickNext={() => {
                handleSlideChange(currentSlideIndex + 1);
              }}
              onClickPrevious={() => {
                handleSlideChange(currentSlideIndex - 1);
              }}
            />
          )} */}
        </Stack>

        {/* <Carousel
          getEmblaApi={setEmbla}
          classNames={classes}
          w="100%"
          speed={30}
          loop
          controlSize={40}
          skipSnaps
          controlsOffset="xs"
          withControls={!activeQuestionId}
          nextControlIcon={<IconChevronRight size={32} />}
          nextControlProps={{
            style: {
              color: theme.colors.primary[1],
              backgroundColor: alpha(theme.colors.primary[8], 1),
              border: "none",
            },
          }}
          previousControlIcon={<IconChevronLeft size={32} />}
          previousControlProps={{
            style: {
              color: theme.colors.background[0],
              backgroundColor: alpha(theme.colors.primary[8], 1),
              border: "none",
            },
          }}
          style={{
            height: "auto",
            // maxHeight: carouselHeight
          }}
          styles={{
            controls: {
              top: 8,
            },
          }}
          onSlideChange={(index) => {
            setCurrentSlideIndex(index);
          }}
        >
          {advizer?.videos
            .filter((video: any) => {
              if (activeQuestionId) {
                return video.question?.id === activeQuestionId;
              }
              return true;
            })
            .map((video: any, index: number) => {
              return (
                <Carousel.Slide key={video.id}>
                  <AdvizerVideo
                    videoId={video.id}
                    title={video.title}
                    url={video.url}
                    questionNumber={video?.questionNumber}
                    withControls={!activeQuestionId}
                    isInBackground={Math.abs(currentSlideIndex - index) > 1}
                  />
                </Carousel.Slide>
              );
            })}
        </Carousel> */}
      </Card.Section>
      {withNavButtons && !activeQuestionId ? (
        <Card.Section>
          <Group justify="center" px="xs" gap="xs" py="xs">
            {advizer.videos.map((video: any, index: number) => {
              return (
                <Button
                  key={video.id}
                  p={2}
                  w={40}
                  h={40}
                  variant={currentSlideIndex === index ? "filled" : "light"}
                  color="primary.3"
                  size="xs"
                  onClick={() => {
                    handleSlideChange(index);
                  }}
                >
                  Q{video.questionNumber}
                </Button>
              );
            })}
          </Group>
        </Card.Section>
      ) : null}
    </Card>
  );
};
