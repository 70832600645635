import { Group, Stack, Space, Box, Title, Avatar } from "@mantine/core";
import { Drawer } from "../ui/Drawer";
import { Button } from "../ui/Button";
import { useActiveUser, useDrawer } from "../../store/selectors";
import { Firebase } from "../../firebase/firebase";
import { Analytics } from "../../services/Analytics";

export const drawerId = "userDrawer";

export function UserDrawer() {
  const user = useActiveUser();
  const { closeDrawer } = useDrawer(drawerId);

  return (
    <Drawer id={drawerId} style={{ zIndex: 1000 }} size="md" position="right">
      <Stack h="100%" justify="space-between" p="md">
        <Stack>
          <Stack gap={0}>
            <Group justify="center">
              <Box
                bg="primary.8"
                p={0}
                bd="1px solid primary.1"
                style={{ borderRadius: "50%" }}
              >
                <Avatar color="secondary.6" radius="xl" size={60} />
              </Box>
            </Group>
            <Space h="xs" />
            {user?.displayName && (
              <Title order={2} c="text.7" ta="center">
                {user?.displayName}
              </Title>
            )}
            <Title order={3} c="text.5" ta="center">
              {user?.email}
            </Title>

            <Space h="xl" />
            <Group justify="center">
              <Button
                variant="transparent"
                size="sm"
                onClick={() => {
                  closeDrawer();
                  Analytics.logout();
                  Firebase.logout();
                }}
              >
                Log out
              </Button>
            </Group>
          </Stack>

          {/* <Divider /> */}
        </Stack>
      </Stack>
    </Drawer>
  );
}
