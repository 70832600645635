import { forwardRef } from "react";
import {
  Chip as MantineChip,
  ChipProps as MantineChipProps,
} from "@mantine/core";

export interface ChipProps extends MantineChipProps {
  onClick?: () => void;
}

export const Chip = forwardRef<HTMLInputElement, ChipProps>((props, ref) => {
  const { className, styles, ...rest } = props;
  return (
    <MantineChip
      className={`adv-chip ${props.checked ? "adv-chip-checked" : ""} ${className || ""}`}
      tt="none"
      radius="xl"
      opacity={props.checked ? 1 : 0.75}
      styles={{
        ...styles,
        iconWrapper: {
          display: "none",
        },
      }}
      {...rest}
      ref={ref}
    />
  );
});

Chip.displayName = "Chip";
