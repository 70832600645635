import {
  Alert,
  Group,
  PasswordInput,
  Skeleton,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import validator from "validator";
import {
  IconBrandTelegram,
  IconChevronRight,
  IconInfoCircle,
  IconLink,
} from "@tabler/icons-react";
import { useBreakpoints, useStyles } from "../styles/useStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Firebase } from "../firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import {
  useIsLoggedIn,
  useIsRestrictedApp,
  usePartnerBySlug,
  usePartners,
} from "../store/selectors";
import { Link } from "../components/ui/Link";
import { config } from "../config";
import { Button } from "../components/ui/Button";
import { NavLink } from "../components/ui/NavLink";
import { PartnerLogo } from "../components/PartnerLogo";
import { Image } from "../components/ui/Image";
import { checkIfMatchesEmailFormat } from "../lib/helpers";
import { Analytics } from "../services/Analytics";

export const Login = (props: {
  isPartnerSelection?: boolean;
  isGeneralLogin?: boolean;
}) => {
  const { isPartnerSelection, isGeneralLogin } = props;
  const { isDesktop } = useBreakpoints();
  const { theme } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [partnerSearchText, setPartnerSearchText] = useState("");
  const { partnerSlug } = useParams();
  const partners = usePartners();
  const partner = usePartnerBySlug(partnerSlug);
  const isRestrictedApp = useIsRestrictedApp();

  const sortedPartners = useMemo(() => {
    if (!partners) return null;

    return [...(partners || [])]
      ?.filter((partner: any) =>
        partner.title.toLowerCase().includes(partnerSearchText.toLowerCase())
      )
      ?.sort((a: any, b: any) => a.title.localeCompare(b.title));
  }, [partners, partnerSearchText]);

  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      // Notifications.showNotification({
      //   title: "You're logged in!",
      // });
      navigate("/");
    }
  }, [navigate, isLoggedIn]);

  const handleSubmit = useCallback(async () => {
    function handleError(errorMessage: string) {
      setEmailError(errorMessage);
      Analytics.invalidEmail({
        inputValue: email,
        error: errorMessage,
      });
    }

    if (!email) {
      handleError("Please enter your email");
      return;
    }

    if (
      partner &&
      !checkIfMatchesEmailFormat({ email, emailFormat: partner.emailFormat })
    ) {
      handleError(`Please enter your email for ${partner.title}`);
      return;
    }

    if (!validator.isEmail(email)) {
      handleError("Please enter a valid email");
      return;
    }

    if (!password) {
      setPasswordError("Please enter your password");
      return;
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    setIsLoggingIn(true);

    if (isPasswordLogin) {
      const response = await Firebase.loginWithEmailAndPassword(
        email,
        password
      );
      if (response?.error) {
        setIsLoggingIn(false);
        setPasswordError(response.error);
        return;
      }
    } else {
      await Firebase.sendSignInLink(email);
      setEmailSent(true);
      setIsLoggingIn(false);
    }
  }, [email, isPasswordLogin, partner, password]);

  const renderWatchAdvizersHero = useCallback(() => {
    if (!isGeneralLogin) return null;
    return (
      <Stack w="100%" gap={0}>
        <Title order={1} lh={1} fz={isDesktop ? 48 : 40} c="text.6">
          Watch Advizers{" "}
        </Title>
        <Title order={1} fz={isDesktop ? 48 : 40} c="text.6">
          and get wize
          <span style={{ opacity: 0.7, fontStyle: "" }}>
            {[".", ".", ".", "e", "r", "s", ".", ".", "."].map(
              (char, index) => (
                <span
                  key={index}
                  style={{
                    fontSize: Math.max(10, isDesktop ? 48 : 40 - index * 4),
                    paddingRight: index * 0.5,
                  }}
                >
                  {char}
                </span>
              )
            )}
          </span>
        </Title>
      </Stack>
    );
  }, [isDesktop, isGeneralLogin]);

  const renderHeaderText = useCallback(() => {
    if (isGeneralLogin) {
      return (
        <Stack w="100%" gap="xs">
          {renderWatchAdvizersHero()}
          <Title order={5} fw={500} c="text.4">
            We'll create an account if you don't have one yet.
          </Title>
          <Space h="xl" />
        </Stack>
      );
    }

    return (
      <>
        {renderWatchAdvizersHero()}
        <Space h="xs" />
      </>
    );
  }, [isGeneralLogin, renderWatchAdvizersHero]);

  const renderTextInput = useCallback(() => {
    return (
      <TextInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
        ta="left"
        label={"Enter your email"}
        placeholder="khaleesi@dragonstone.edu"
        error={emailError}
        w="100%"
        size="lg"
        labelProps={{
          size: "md",
          fw: 800,
          c: "text.8",
          mb: 4,
          pl: 4,
        }}
      />
    );
  }, [email, emailError, handleSubmit]);

  const renderPasswordInput = useCallback(() => {
    return (
      <PasswordInput
        w="100%"
        size="lg"
        value={password}
        placeholder="********"
        error={passwordError}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
      />
    );
  }, [password, handleSubmit, passwordError]);

  const renderGoogleLogin = useCallback(() => {
    return (
      <Button
        variant="filled"
        radius="xl"
        size="lg"
        w="100%"
        className="gsi-material-button"
        onClick={() => Firebase.signInWithGoogle()}
        style={{
          width: "100%",
          height: 50,
          borderRadius: "40px",
        }}
      >
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              //   xmlns:xlink="http://www.w3.org/1999/xlink"
              style={{ display: "block" }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span
            className="gsi-material-button-contents"
            style={{ fontSize: "16px", padding: "2px 0px" }}
          >
            Sign in with Google
          </span>
          <span style={{ display: "none" }}>Sign in with Google</span>
        </div>
      </Button>
    );
  }, []);

  const renderLoginOptions = useCallback(
    (props?: { noGoogle?: boolean }) => {
      const { noGoogle } = props || {};
      if (isPasswordLogin) {
        return (
          <>
            {" "}
            <Space h="sm" />
            {renderTextInput()}
            <Space h="sm" />
            {renderPasswordInput()}
            <Space h="sm" />
            <Button
              variant="filled"
              radius="xl"
              size="lg"
              w="100%"
              onClick={handleSubmit}
              loading={isLoggingIn}
            >
              Login{" "}
            </Button>{" "}
            {noGoogle ? null : (
              <>
                <Space h="lg" />
                <Text size="md" c="text.4">
                  or
                </Text>
                <Space h="lg" />
                {renderGoogleLogin()}
              </>
            )}
            <Space h="xl" />
          </>
        );
      }

      return (
        <>
          <Space h="sm" />

          <Stack w="100%">
            {renderTextInput()}
            <Button
              variant="filled"
              radius="xl"
              size="lg"
              w="100%"
              leftSection={<IconLink />}
              onClick={handleSubmit}
            >
              Get a magic link in your inbox
            </Button>{" "}
          </Stack>
          {noGoogle ? null : (
            <>
              <Space h="lg" />
              <Text size="md" c="text.4">
                or
              </Text>
              <Space h="lg" />
              {renderGoogleLogin()}
            </>
          )}
          <Space h="xl" />
          <Group gap={0}>
            <Text size="md" c="text.4">
              Want to sign in with a password?
            </Text>
            <Button
              variant="transparent"
              size="md"
              c="text.4"
              px={4}
              style={{ textDecoration: "underline", fontWeight: 500 }}
              onClick={(event) => {
                setIsPasswordLogin(true);
                Analytics.click({
                  action: "Login with password",
                  event,
                });
              }}
            >
              Click here
            </Button>
          </Group>
        </>
      );
    },
    [
      handleSubmit,
      isLoggingIn,
      isPasswordLogin,
      renderGoogleLogin,
      renderPasswordInput,
      renderTextInput,
    ]
  );

  const renderChooseLoginDefault = useCallback(() => {
    return (
      <Stack w="100%" gap="md">
        {isRestrictedApp && (
          <Alert
            color="orange"
            icon={<IconInfoCircle />}
            title="Just a sec!"
            ta="left"
          >
            Please create a free account to keep watching videos.
          </Alert>
        )}
        <Title order={1} c="text.5">
          Are you a student?
        </Title>
        <Space h="sm" />
        <Button fullWidth size="md" to="/login/partners">
          Yep, I'll log in with my school email
        </Button>
        <Button to="/login/general" fullWidth size="md" variant="outline">
          Naw, I'm not a student
        </Button>
        <Button fullWidth size="md" variant="light" to="/login/partners">
          Hmmm, I'm not sure
        </Button>
      </Stack>
    );
  }, []);

  const renderTerms = useCallback(() => {
    if (isPartnerSelection) return null;
    return (
      <Text size="sm" c="dimmed" opacity={0.8}>
        By using this app, you agree to our <br />
        <Link to={config.termsUrl} target="_blank">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link to={config.privacyUrl} target="_blank">
          Privacy Policy
        </Link>
      </Text>
    );
  }, [isPartnerSelection]);

  const renderPartnerSelection = useCallback(() => {
    return (
      <Stack w="100%" gap="xs">
        <Space h="lg" />
        <Title order={1} c="text.5">
          Choose your Advize partner
        </Title>
        <Group gap={0} justify="center">
          <Text size="sm" c="text.4">
            Don't see your school? <br />
            <Link to="/login/general">
              Click here to log in without a partner
            </Link>
          </Text>
        </Group>
        <Space h="sm" />
        <TextInput
          value={partnerSearchText}
          onChange={(e) => setPartnerSearchText(e.target.value)}
          placeholder="Search for your school"
          size="md"
        />
        <Stack w="100%" gap={0}>
          {(sortedPartners || Array.from({ length: 10 })).map(
            (partner: any, index: number) => {
              if (!sortedPartners?.length) {
                return <Skeleton key={index} w="100%" h={50} />;
              }
              return (
                <NavLink
                  className="hoverable"
                  key={partner.id || index}
                  size="md"
                  to={`/login/${partner.slug}`}
                  CustomIcon={<PartnerLogo partnerId={partner.id} />}
                  rightSection={
                    <IconChevronRight
                      size="0.8rem"
                      stroke={1.5}
                      className="mantine-rotate-rtl"
                    />
                  }
                >
                  {partner.title}
                </NavLink>
              );
            }
          )}
        </Stack>
      </Stack>
    );
  }, [partnerSearchText, sortedPartners]);

  const renderPartnerLoginHeader = useCallback(() => {
    return (
      <Stack w="100%" gap={0} align="center">
        <Image
          src={partner?.logoURL}
          alt={partner?.title}
          w={300}
          mah={300}
          // w={200}
          ratio={16 / 9}
          fit={partner.title.includes("Knauss") ? "contain" : "cover"}
          // noRatio
        />
      </Stack>
    );
  }, [partner]);

  const renderPartnerLogin = useCallback(() => {
    if (!partner) return null;
    return (
      <Stack gap={0} w="100%" align="center">
        {renderPartnerLoginHeader()}
        <Space h="md" />
        {renderLoginOptions({ noGoogle: true })}
      </Stack>
    );
  }, [partner, renderLoginOptions, renderPartnerLoginHeader]);

  const renderContent = useCallback(() => {
    if (partner) {
      return renderPartnerLogin();
    }
    if (isPartnerSelection) {
      return renderPartnerSelection();
    }

    if (isGeneralLogin) {
      return renderLoginOptions();
    }

    return renderChooseLoginDefault();
  }, [
    partner,
    isPartnerSelection,
    isGeneralLogin,
    renderChooseLoginDefault,
    renderPartnerLogin,
    renderPartnerSelection,
    renderLoginOptions,
  ]);

  if (emailSent) {
    return (
      <Stack
        align="center"
        justify="center"
        h="100%"
        mih="100vh"
        ta="center"
        gap={0}
        p="sm"
        maw={500}
        mx="auto"
      >
        <Group w="100%" align="center" justify="center">
          <IconBrandTelegram size={64} color={theme.colors.text[6]} />
        </Group>
        <Space h="lg" />
        <Title order={1} lh={1} fz={isDesktop ? 48 : 40} c="text.6">
          Check your inbox!
        </Title>
        <Space h="xs" />
        <Text size="lg" c="text.5">
          We've sent you a magic link to {email || "your email"}. Click the link
          to sign in and start watching Advizers.
        </Text>
      </Stack>
    );
  }

  return (
    <Stack
      align="center"
      justify={isPartnerSelection ? "flex-start" : "center"}
      bg={theme?.colors?.loginBackground?.[0] || theme.colors.background[0]}
      // h="100%"
      mih="100vh"
      ta="center"
      gap={0}
      p="sm"
      pt={isPartnerSelection || isGeneralLogin ? 60 : 36}
      pb={100}
      w="100%"
      mx="auto"
    >
      <Stack w="100%" maw={450} gap={0} align="center">
        {/* <Space h="sm" /> */}
        {renderHeaderText()}
        {/* <Space h="xl" /> */}
        {renderContent()}
        <Space h="xl" />
        {renderTerms()}
      </Stack>
    </Stack>
  );
};
