import { forwardRef } from "react";
import {
  Image as MantineImage,
  ImageProps as MantineImageProps,
  AspectRatio,
} from "@mantine/core";

interface AdditionalImageProps extends MantineImageProps {
  ratio?: number;
  width?: number | string;
  noRatio?: boolean;
}

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> &
  AdditionalImageProps;

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const { ratio = 1, width, noRatio, ...rest } = props;
  if (noRatio) {
    return <MantineImage {...rest} ref={ref} />;
  }
  return (
    <AspectRatio ratio={ratio} maw={width}>
      <MantineImage {...rest} ref={ref} />
    </AspectRatio>
  );
});

Image.displayName = "Image";
