import {
  IconBrain,
  IconBrandSlack,
  IconHelp,
  IconHome,
  IconInfoCircle,
} from "@tabler/icons-react";
import { PartnerLogo } from "../components/PartnerLogo";
import { config } from "../config";

export const NAV_ITEMS = [
  {
    href: "/",
    label: "Home",
    Icon: IconHome,
    showOnDesktopNavbar: true,
  },
  {
    label: "Alum Advizers",
    hide: (partnerId: string) => !partnerId,
    CustomIcon: (partnerId: string) => (
      <PartnerLogo width={26} disableClick partnerId={partnerId} />
    ),
  },
  {
    href: "https://www.advizehub.com/insights",
    target: "_blank",
    label: "Blog",
    Icon: IconBrain,
    showOnDesktopNavbar: true,
  },
  {
    href: config.supportEmailLink,
    label: "Support",
    Icon: IconHelp,
    target: "_blank",
    dontClose: true,
    showOnDesktopNavbar: true,
  },
  {
    href: config.slackInviteLink,
    label: "Slack Community",
    Icon: IconBrandSlack,
    target: "_blank",
    dontClose: true,
    showOnDesktopNavbar: true,
  },
  {
    href: "https://www.advizehub.com/about",
    label: "About Advize",
    Icon: IconInfoCircle,
  },
  {},
];
