import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
// import { useCurrentPartner } from "../hooks";

export const useIsMobile = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(max-width: ${theme.breakpoints.mobileMax})`, true);
};

export const useIsTablet = () => {
  const theme = useMantineTheme();
  return useMediaQuery(
    `(min-width: ${theme.breakpoints.mobileMax}) and (max-width: ${theme.breakpoints.tabletMax})`,
    true
  );
};

export const useIsLaptop = () => {
  const theme = useMantineTheme();
  return useMediaQuery(
    `(min-width: ${theme.breakpoints.tabletMax}) and (max-width: ${theme.breakpoints.laptopMax})`,
    true
  );
};

export const useIsTabletOrBigger = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(min-width: ${theme.breakpoints.mobileMax})`, true);
};

export const useIsLaptopOrBigger = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(min-width: ${theme.breakpoints.tabletMax})`, true);
};

export const useIsDesktop = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(min-width: ${theme.breakpoints.laptopMax})`, true);
};

export const useBreakpoints = () => {
  // const theme = useMantineTheme();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLaptop = useIsLaptop();
  const isTabletOrBigger = useIsTabletOrBigger();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isDesktop = useIsDesktop();
  return {
    isMobile,
    isTablet,
    isTabletOrBigger,
    isLaptop,
    isLaptopOrBigger,
    isDesktop,
  };
};

export const useStyles = () => {
  const { isLaptopOrBigger } = useBreakpoints();
  const theme = useMantineTheme();

  const headerHeight = isLaptopOrBigger ? 65 : 50;

  return {
    dimensions: {
      headerHeight,
      maxAppWidth: 1950,
    },
    theme,
    // colors: {
    //   ...theme.colors,
    //   background: theme.colors.slate,
    //   text: theme.colors.gray,
    // },
  };
};
