import { notifications } from "@mantine/notifications";

class NotificationServiceClass {
  showNotification(params: {
    title: string;
    message?: string;
    autoClose?: number | false;
    error?: boolean;
  }) {
    notifications.show({
      position: "bottom-right",
      autoClose: params.autoClose === false ? false : params.autoClose || 5000,
      title: params.title,
      message: params.message,
      color: params.error ? "red" : undefined,
    });
  }
}

export const Notifications = new NotificationServiceClass();
