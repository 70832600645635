import { IAdvizerModel } from "./Advizer";

export class AdvizerVideo {
  id: string;
  titleRaw: string;
  url: string;
  question: any;
  advizer: IAdvizerModel;
  constructor(apiData: any) {
    this.id = apiData.id;
    this.titleRaw = apiData.title;
    this.url = apiData.url;
    this.question = apiData.question;
    this.advizer = apiData.advizer;
  }

  get title() {
    return `${this.question?.title || this.titleRaw || ""}`;
  }

  get questionNumber() {
    return this.question?.number;
  }
}

export interface IAdvizerVideo extends AdvizerVideo {}
