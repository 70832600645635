import { useEffect } from "react";
import { useStyles } from "../styles/useStyles";
import { useStore } from "../store";

export function Confetti() {
  const { theme } = useStyles();
  const showConfetti = useStore((state) => state.experience.showConfetti);
  const setShowConfetti = useStore((state) => state.experience.setShowConfetti);
  // this should be run only once per application lifetime
  useEffect(() => {
    const colors = [
      theme.colors.primary[2],
      theme.colors.secondary[4],
      theme.colors.primary[6],
      theme.colors.secondary[8],
      theme.colors.primary[9],
      theme.colors.primary[8],
      theme.colors.secondary[6],
      theme.colors.primary[4],
      theme.colors.secondary[2],
    ];

    const defaults = {
      position: { y: 100 },
      shapes: ["circle"],
      spread: 20,
      count: 100,
      startVelocity: 100,
      // colors,
    };

    async function fire(opts: any) {
      // @ts-expect-error confetti is not typed
      await confetti(Object.assign({}, defaults, opts));
    }

    const configs = [
      {
        angle: 150,
        timeout: 0,
      },
      {
        angle: 130,
        timeout: 300,
      },
      {
        angle: 115,
        timeout: 600,
      },
      {
        angle: 100,
        timeout: 900,
      },
      {
        angle: 90,
        timeout: 1000,
      },
      {
        angle: 80,
        timeout: 900,
      },
      {
        angle: 65,
        timeout: 600,
      },
      {
        angle: 50,
        timeout: 300,
      },
      {
        angle: 30,
        timeout: 0,
      },
    ];

    if (showConfetti) {
      setTimeout(async () => {
        for (let index = 0; index < configs.length; index++) {
          const config = configs[index];
          setTimeout(async () => {
            fire({ ...config, colors: [colors[index]] });
          }, config.timeout);
        }
      }, 1000);

      setShowConfetti(false);
    }
  }, [
    theme.colors.primary,
    theme.colors.secondary,
    showConfetti,
    setShowConfetti,
  ]);

  return null;
}
