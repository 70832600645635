import { useEffect } from "react";
import { Firebase } from "../firebase/firebase";
import { Stack } from "@mantine/core";
import { useActiveUser } from "../store/selectors";
import { useNavigate } from "react-router-dom";

export const AuthRedirect = () => {
  const user = useActiveUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    async function init() {
      const user = await Firebase.signInFromLink();
      console.log("LOGGED IN", { user });
    }

    init();
  }, []);

  return <Stack h="100%"></Stack>;
};
