import { Image } from "./ui/Image";
import { usePartner } from "../store/selectors";
import { useStyles } from "../styles/useStyles";
import { Skeleton } from "@mantine/core";
import { useCallback } from "react";

export const PartnerLogo = (props: {
  width?: number;
  height?: number;
  radius?: number;
  src?: string;
  disableClick?: boolean;
  partnerId?: string;
  backgroundColor?: string;
  noLink?: boolean;
}) => {
  const { theme } = useStyles();
  const { width, height, radius, src, partnerId, backgroundColor } = props;
  const partner = usePartner(partnerId);

  const renderCore = useCallback(() => {
    return (
      <>
        {!src && !partner?.logoURL ? (
          <Skeleton
            width={width || 32}
            height={width || height || 32}
            radius={radius || 6}
          />
        ) : (
          <Image
            width={width || 32}
            height={width || height || 32}
            radius={radius || 6}
            src={src || partner?.iconURL || ""}
            style={{
              backgroundColor: backgroundColor || theme.colors.background[1],
            }}
          />
        )}
      </>
    );
  }, [
    backgroundColor,
    height,
    partner?.iconURL,
    partner?.logoURL,
    radius,
    src,
    theme.colors.background,
    width,
  ]);

  return renderCore();
};
