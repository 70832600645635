import { forwardRef } from "react";
import {
  Badge as MantineBadge,
  BadgeProps as MantineBadgeProps,
  Box,
} from "@mantine/core";

export interface BadgeProps extends MantineBadgeProps {
  onClick?: () => void;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  const { className, onClick, ...rest } = props;
  return (
    <Box role="button" onClick={onClick} p={0}>
      <MantineBadge
        className={`clickable ${className}`}
        tt="none"
        {...rest}
        ref={ref}
      />
    </Box>
  );
});

Badge.displayName = "Badge";
