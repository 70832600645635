import { MantineColorsTuple, lighten } from "@mantine/core";

// https://mantine.dev/colors-generator/

export const advizeBlue: MantineColorsTuple = [
  "#ecf4fd",
  "#d7e4f6",
  "#aac7ee",
  "#7ca8e8",
  "#568ee3",
  "#407de1",
  "#3575e0",
  "#2963c8",
  "#2058b3",
  "#0e4692",
];

export const advizeGreen: MantineColorsTuple = [
  "#e2fff7",
  "#cefeef",
  "#a0fbdd",
  "#6ef9cb",
  "#46f6bc",
  "#2ef6b2",
  "#1df5ac",
  "#09da96",
  "#00c184",
  "#00a770",
];

export const slate: MantineColorsTuple = [
  "#f8fafc",
  "#f1f5f9",
  "#e2e8f0",
  "#cbd5e1",
  "#94a3b8",
  "#64748b",
  "#475569",
  "#334155",
  "#1e293b",
  "#0f172a",
];

export const advizeDarkGreen: MantineColorsTuple = [
  "#eef9f3",
  "#e3ece7",
  "#c6d5ce",
  "#a8beb2",
  "#8dab9b",
  "#7c9f8c",
  "#729983",
  "#608571",
  "#537763",
  "#426754",
];

const sacStateGreenBase = "#043927";
export const sacStateGreen: MantineColorsTuple = [
  lighten(sacStateGreenBase, 0.9),
  lighten(sacStateGreenBase, 0.8),
  lighten(sacStateGreenBase, 0.7),
  lighten(sacStateGreenBase, 0.6),
  lighten(sacStateGreenBase, 0.5),
  lighten(sacStateGreenBase, 0.4),
  lighten(sacStateGreenBase, 0.3),
  lighten(sacStateGreenBase, 0.2),
  lighten(sacStateGreenBase, 0.1),
  sacStateGreenBase,
];

const sacStateGoldBase = "#78682d";
export const sacStateGold: MantineColorsTuple = [
  lighten(sacStateGoldBase, 0.9),
  lighten(sacStateGoldBase, 0.8),
  lighten(sacStateGoldBase, 0.7),
  lighten(sacStateGoldBase, 0.6),
  lighten(sacStateGoldBase, 0.5),
  lighten(sacStateGoldBase, 0.4),
  lighten(sacStateGoldBase, 0.3),
  lighten(sacStateGoldBase, 0.2),
  lighten(sacStateGoldBase, 0.1),
  sacStateGoldBase,
];

const asuMaroonBase = "#8C1D40";
export const asuMaroon: MantineColorsTuple = [
  lighten(asuMaroonBase, 0.9),
  lighten(asuMaroonBase, 0.8),
  lighten(asuMaroonBase, 0.7),
  lighten(asuMaroonBase, 0.6),
  lighten(asuMaroonBase, 0.5),
  lighten(asuMaroonBase, 0.4),
  lighten(asuMaroonBase, 0.3),
  lighten(asuMaroonBase, 0.2),
  lighten(asuMaroonBase, 0.1),
  asuMaroonBase,
];

export const asuGoldBase = "#FFC627";
export const asuGold: MantineColorsTuple = [
  lighten(asuGoldBase, 0.95),
  lighten(asuGoldBase, 0.9),
  lighten(asuGoldBase, 0.8),
  lighten(asuGoldBase, 0.6),
  lighten(asuGoldBase, 0.5),
  lighten(asuGoldBase, 0.4),
  lighten(asuGoldBase, 0.3),
  lighten(asuGoldBase, 0.2),
  lighten(asuGoldBase, 0.1),
  asuGoldBase,
];

export const allianceGreenBase = "#36A857";
export const allianceGreen: MantineColorsTuple = [
  lighten(allianceGreenBase, 0.9),
  lighten(allianceGreenBase, 0.8),
  lighten(allianceGreenBase, 0.7),
  lighten(allianceGreenBase, 0.6),
  lighten(allianceGreenBase, 0.5),
  lighten(allianceGreenBase, 0.4),
  lighten(allianceGreenBase, 0.3),
  lighten(allianceGreenBase, 0.2),
  lighten(allianceGreenBase, 0.1),
  allianceGreenBase,
];

export const allianceOrangeBase = "#FFC700";
export const allianceOrange: MantineColorsTuple = [
  lighten(allianceOrangeBase, 0.9),
  lighten(allianceOrangeBase, 0.8),
  lighten(allianceOrangeBase, 0.7),
  lighten(allianceOrangeBase, 0.6),
  lighten(allianceOrangeBase, 0.5),
  lighten(allianceOrangeBase, 0.4),
  lighten(allianceOrangeBase, 0.3),
  lighten(allianceOrangeBase, 0.2),
  lighten(allianceOrangeBase, 0.1),
  allianceOrangeBase,
];

export const californiaLutheranPrimaryBase = "#3b2360";
export const californiaLutheranPrimary: MantineColorsTuple = [
  lighten(californiaLutheranPrimaryBase, 0.9),
  lighten(californiaLutheranPrimaryBase, 0.8),
  lighten(californiaLutheranPrimaryBase, 0.7),
  lighten(californiaLutheranPrimaryBase, 0.6),
  lighten(californiaLutheranPrimaryBase, 0.5),
  lighten(californiaLutheranPrimaryBase, 0.4),
  lighten(californiaLutheranPrimaryBase, 0.3),
  lighten(californiaLutheranPrimaryBase, 0.2),
  lighten(californiaLutheranPrimaryBase, 0.1),
  californiaLutheranPrimaryBase,
];

export const californiaLutheranSecondaryBase = "#ffc222";
export const californiaLutheranSecondary: MantineColorsTuple = [
  lighten(californiaLutheranSecondaryBase, 0.9),
  lighten(californiaLutheranSecondaryBase, 0.8),
  lighten(californiaLutheranSecondaryBase, 0.7),
  lighten(californiaLutheranSecondaryBase, 0.6),
  lighten(californiaLutheranSecondaryBase, 0.5),
  lighten(californiaLutheranSecondaryBase, 0.4),
  lighten(californiaLutheranSecondaryBase, 0.3),
  lighten(californiaLutheranSecondaryBase, 0.2),
  lighten(californiaLutheranSecondaryBase, 0.1),
  californiaLutheranSecondaryBase,
];

export const compassDiversifiedPrimaryBase = "#0B2337";
export const compassDiversifiedPrimary: MantineColorsTuple = [
  lighten(compassDiversifiedPrimaryBase, 0.9),
  lighten(compassDiversifiedPrimaryBase, 0.8),
  lighten(compassDiversifiedPrimaryBase, 0.7),
  lighten(compassDiversifiedPrimaryBase, 0.6),
  lighten(compassDiversifiedPrimaryBase, 0.5),
  lighten(compassDiversifiedPrimaryBase, 0.4),
  lighten(compassDiversifiedPrimaryBase, 0.3),
  lighten(compassDiversifiedPrimaryBase, 0.2),
  lighten(compassDiversifiedPrimaryBase, 0.1),
  compassDiversifiedPrimaryBase,
];

export const compassDiversifiedSecondaryBase = "#DEB873";
export const compassDiversifiedSecondary: MantineColorsTuple = [
  lighten(compassDiversifiedSecondaryBase, 0.9),
  lighten(compassDiversifiedSecondaryBase, 0.8),
  lighten(compassDiversifiedSecondaryBase, 0.7),
  lighten(compassDiversifiedSecondaryBase, 0.6),
  lighten(compassDiversifiedSecondaryBase, 0.5),
  lighten(compassDiversifiedSecondaryBase, 0.4),
  lighten(compassDiversifiedSecondaryBase, 0.3),
  lighten(compassDiversifiedSecondaryBase, 0.2),
  lighten(compassDiversifiedSecondaryBase, 0.1),
  compassDiversifiedSecondaryBase,
];

export const hericansPrimaryBase = "#000";
export const hericansPrimary: MantineColorsTuple = [
  lighten(hericansPrimaryBase, 0.9),
  lighten(hericansPrimaryBase, 0.8),
  lighten(hericansPrimaryBase, 0.7),
  lighten(hericansPrimaryBase, 0.6),
  lighten(hericansPrimaryBase, 0.5),
  lighten(hericansPrimaryBase, 0.4),
  lighten(hericansPrimaryBase, 0.3),
  lighten(hericansPrimaryBase, 0.2),
  lighten(hericansPrimaryBase, 0.1),
  hericansPrimaryBase,
];

export const hericansSecondaryBase = "#00afff";
// export const hericansSecondaryBase = "#9341cb";
export const hericansSecondary: MantineColorsTuple = [
  lighten(hericansSecondaryBase, 0.9),
  lighten(hericansSecondaryBase, 0.8),
  lighten(hericansSecondaryBase, 0.7),
  lighten(hericansSecondaryBase, 0.6),
  lighten(hericansSecondaryBase, 0.5),
  lighten(hericansSecondaryBase, 0.4),
  lighten(hericansSecondaryBase, 0.3),
  lighten(hericansSecondaryBase, 0.2),
  lighten(hericansSecondaryBase, 0.1),
  hericansSecondaryBase,
];

export const mindsMatterPrimaryBase = "#191919";
export const mindsMatterPrimary: MantineColorsTuple = [
  lighten(mindsMatterPrimaryBase, 0.9),
  lighten(mindsMatterPrimaryBase, 0.8),
  lighten(mindsMatterPrimaryBase, 0.7),
  lighten(mindsMatterPrimaryBase, 0.6),
  lighten(mindsMatterPrimaryBase, 0.5),
  lighten(mindsMatterPrimaryBase, 0.4),
  lighten(mindsMatterPrimaryBase, 0.3),
  lighten(mindsMatterPrimaryBase, 0.2),
  lighten(mindsMatterPrimaryBase, 0.1),
  mindsMatterPrimaryBase,
];

export const mindsMatterSecondaryBase = "#fafafa";
export const mindsMatterSecondary: MantineColorsTuple = [
  lighten(mindsMatterSecondaryBase, 0.9),
  lighten(mindsMatterSecondaryBase, 0.8),
  lighten(mindsMatterSecondaryBase, 0.7),
  lighten(mindsMatterSecondaryBase, 0.6),
  lighten(mindsMatterSecondaryBase, 0.5),
  lighten(mindsMatterSecondaryBase, 0.4),
  lighten(mindsMatterSecondaryBase, 0.3),
  lighten(mindsMatterSecondaryBase, 0.2),
  lighten(mindsMatterSecondaryBase, 0.1),
  mindsMatterSecondaryBase,
];

export const berkeleyPrimaryBase = "#002676";
export const berkeleyPrimary: MantineColorsTuple = [
  lighten(berkeleyPrimaryBase, 0.9),
  lighten(berkeleyPrimaryBase, 0.8),
  lighten(berkeleyPrimaryBase, 0.7),
  lighten(berkeleyPrimaryBase, 0.6),
  lighten(berkeleyPrimaryBase, 0.5),
  lighten(berkeleyPrimaryBase, 0.4),
  lighten(berkeleyPrimaryBase, 0.3),
  lighten(berkeleyPrimaryBase, 0.2),
  lighten(berkeleyPrimaryBase, 0.1),
  berkeleyPrimaryBase,
];

export const berkeleySecondaryBase = "#FDB515";
export const berkeleySecondary: MantineColorsTuple = [
  lighten(berkeleySecondaryBase, 0.9),
  lighten(berkeleySecondaryBase, 0.8),
  lighten(berkeleySecondaryBase, 0.7),
  lighten(berkeleySecondaryBase, 0.6),
  lighten(berkeleySecondaryBase, 0.5),
  lighten(berkeleySecondaryBase, 0.4),
  lighten(berkeleySecondaryBase, 0.3),
  lighten(berkeleySecondaryBase, 0.2),
  lighten(berkeleySecondaryBase, 0.1),
  berkeleySecondaryBase,
];

export const uclaAndersonPrimaryBase = "#2774AE";
export const uclaAndersonPrimary: MantineColorsTuple = [
  lighten(uclaAndersonPrimaryBase, 0.9),
  lighten(uclaAndersonPrimaryBase, 0.8),
  lighten(uclaAndersonPrimaryBase, 0.7),
  lighten(uclaAndersonPrimaryBase, 0.6),
  lighten(uclaAndersonPrimaryBase, 0.5),
  lighten(uclaAndersonPrimaryBase, 0.4),
  lighten(uclaAndersonPrimaryBase, 0.3),
  lighten(uclaAndersonPrimaryBase, 0.2),
  lighten(uclaAndersonPrimaryBase, 0.1),
  uclaAndersonPrimaryBase,
];

export const uclaAndersonSecondaryBase = "#f1f1f1";
export const uclaAndersonSecondary: MantineColorsTuple = [
  lighten(uclaAndersonSecondaryBase, 0.9),
  lighten(uclaAndersonSecondaryBase, 0.8),
  lighten(uclaAndersonSecondaryBase, 0.7),
  lighten(uclaAndersonSecondaryBase, 0.6),
  lighten(uclaAndersonSecondaryBase, 0.5),
  lighten(uclaAndersonSecondaryBase, 0.4),
  lighten(uclaAndersonSecondaryBase, 0.3),
  lighten(uclaAndersonSecondaryBase, 0.2),
  lighten(uclaAndersonSecondaryBase, 0.1),
  uclaAndersonSecondaryBase,
];

export const knaussPrimaryBase = "#00294e";
export const knaussPrimary: MantineColorsTuple = [
  lighten(knaussPrimaryBase, 0.9),
  lighten(knaussPrimaryBase, 0.8),
  lighten(knaussPrimaryBase, 0.7),
  lighten(knaussPrimaryBase, 0.6),
  lighten(knaussPrimaryBase, 0.5),
  lighten(knaussPrimaryBase, 0.4),
  lighten(knaussPrimaryBase, 0.3),
  lighten(knaussPrimaryBase, 0.2),
  lighten(knaussPrimaryBase, 0.1),
  knaussPrimaryBase,
];

export const knaussSecondaryBase = "#75bee9";
export const knaussSecondary: MantineColorsTuple = [
  lighten(knaussSecondaryBase, 0.9),
  lighten(knaussSecondaryBase, 0.8),
  lighten(knaussSecondaryBase, 0.7),
  lighten(knaussSecondaryBase, 0.6),
  lighten(knaussSecondaryBase, 0.5),
  lighten(knaussSecondaryBase, 0.4),
  lighten(knaussSecondaryBase, 0.3),
  lighten(knaussSecondaryBase, 0.2),
  lighten(knaussSecondaryBase, 0.1),
  knaussSecondaryBase,
];
