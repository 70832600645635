import { Group, Space, Stack, Text, Title } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { Image } from "../ui/Image";
import sadEscobarMeme from "../../assets/memes/sad-escobar-meme.jpg";
import {
  useIndustryFilterOptions,
  useJobFunctionFilterOptions,
  useSelectedAdvizerSearchFilters,
  useSubjectFilterOptions,
  useTraitFilterOptions,
} from "../../store/selectors";
import { Analytics } from "../../services/Analytics";

export function ErrorOrNoResults() {
  const selectedAdvizerSearchFilters = useSelectedAdvizerSearchFilters();
  const { industries } = useIndustryFilterOptions();
  const { subjects } = useSubjectFilterOptions();
  const { jobFunctions } = useJobFunctionFilterOptions();
  const { traits } = useTraitFilterOptions();
  const { searchText, ...restOfSelectedAdvizerSearchFilters } =
    selectedAdvizerSearchFilters;

  const searchStringCombined = useMemo(() => {
    const dataString = [
      Object.keys(restOfSelectedAdvizerSearchFilters.industries).map(
        (industryId) =>
          industries.find((industry) => industry.id === industryId)?.title
      ),
      Object.keys(restOfSelectedAdvizerSearchFilters.subjects).map(
        (subjectId) =>
          subjects.find((subject) => subject.id === subjectId)?.title
      ),
      Object.keys(restOfSelectedAdvizerSearchFilters.jobFunctions).map(
        (jobFunctionId) =>
          jobFunctions.find((jobFunction) => jobFunction.id === jobFunctionId)
            ?.title
      ),
      Object.keys(restOfSelectedAdvizerSearchFilters.traits).map(
        (traitId) => traits.find((trait) => trait.id === traitId)?.title
      ),
      Object.keys(restOfSelectedAdvizerSearchFilters.companies),
    ]
      .flat()
      .join("|");

    return dataString;
  }, [restOfSelectedAdvizerSearchFilters]);

  useEffect(() => {
    Analytics.noAdvizersFound({
      inputValue: searchText,
      filterValue: searchStringCombined,
    });
  }, [searchText, searchStringCombined]);

  return (
    <Stack
      pt={60}
      maw={400}
      mx="auto"
      ta="center"
      gap={0}
      justify="center"
      px="md"
    >
      <Group justify="center">
        <Image src={sadEscobarMeme} alt="No Results Meme" w={300} />
      </Group>
      <Space h="xl" />

      <Title order={2} c="text.7">
        We don't have any advizers that match your search... yet!
      </Title>
      <Space h={10} />
      <Text c="text.5" size="sm">
        We've saved your search and will do our best to interview Advizers that
        match what you're looking for.
      </Text>
    </Stack>
  );
}
