import axios from "axios";
import { config } from "../config";

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

type IGenericApiQueryParams = {
  model?: string;
  include?: Record<string, any>;
  where?: Record<string, any>;
  take?: number;
  skip?: number;
  noCache?: boolean;
  cacheInvalidator?: number;
};

class AdvizeApiClass {
  async query(params: IGenericApiQueryParams) {
    return api.get("/query", {
      params: { ...params, cacheInvalidator: config.cacheInvalidator },
    });
  }

  async queryPartners(params?: IGenericApiQueryParams) {
    return this.query({ model: "partner", ...params });
  }

  async querySubjects(params?: IGenericApiQueryParams) {
    return this.query({ model: "subject", ...params });
  }

  async queryJobFunctions(params?: IGenericApiQueryParams) {
    return this.query({ model: "jobFunction", ...params });
  }

  async queryTraits(params?: IGenericApiQueryParams) {
    return this.query({ model: "trait", ...params });
  }

  async queryIndustries(params?: IGenericApiQueryParams) {
    return this.query({ model: "industry", ...params });
  }

  async queryQuestions(params?: IGenericApiQueryParams) {
    return this.query({
      model: "question",
      include: { partners: true },
      ...params,
    });
  }

  async queryAdvizersWithEverything(params?: IGenericApiQueryParams) {
    return this.query({
      model: "advizer",
      ...params,
      include: {
        interviews: {
          include: {
            videos: { include: { question: true } },
          },
        },
        partners: true,
        subjects: true,
        traits: true,
        industries: true,
        jobFunctions: true,
      },
    });
  }
}

export const AdvizeApi = new AdvizeApiClass();
