import {
  colorsTuple,
  createTheme,
  MantineColorShade,
  MantineColorsTuple,
} from "@mantine/core";
import { useActiveUserPartner } from "../store/selectors";
import {
  advizeBlue,
  advizeGreen,
  allianceGreen,
  allianceOrange,
  asuGold,
  asuMaroon,
  berkeleyPrimary,
  berkeleySecondary,
  californiaLutheranPrimary,
  californiaLutheranSecondary,
  compassDiversifiedPrimary,
  compassDiversifiedSecondary,
  hericansPrimary,
  hericansSecondary,
  knaussPrimary,
  knaussSecondary,
  mindsMatterPrimary,
  mindsMatterSecondary,
  sacStateGold,
  sacStateGreen,
  slate,
  uclaAndersonPrimary,
  uclaAndersonSecondary,
} from "./colors";

type IThemeOverride = {
  primaryShade: MantineColorShade;
  colors: {
    primary: MantineColorsTuple;
    secondary: MantineColorsTuple;
    background: MantineColorsTuple;
    border: MantineColorsTuple;
    text: MantineColorsTuple;
    loginBackground?: MantineColorsTuple;
  };
};

const advizeTheme: IThemeOverride = {
  primaryShade: 6,
  colors: {
    primary: advizeBlue,
    secondary: advizeGreen,
    background: slate,
    border: slate,
    text: slate,
  },
};

const schoolThemes: Record<string, IThemeOverride> = {
  ["sacramento-state"]: {
    primaryShade: 7,
    colors: {
      primary: sacStateGreen,
      secondary: sacStateGold,
      background: sacStateGold,
      border: sacStateGold,
      text: slate,
    },
  },
  ["asu-w.p.-carey-school-of-business"]: {
    primaryShade: 7,
    colors: {
      primary: asuMaroon,
      secondary: asuGold,
      background: asuGold,
      loginBackground: colorsTuple(Array.from({ length: 10 }, () => "#fff")),
      border: asuGold,
      text: slate,
    },
  },
  ["alliance-college-ready-public-schools"]: {
    primaryShade: 7,
    colors: {
      primary: allianceGreen,
      secondary: allianceOrange,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["california-lutheran-university"]: {
    primaryShade: 7,
    colors: {
      primary: californiaLutheranPrimary,
      secondary: californiaLutheranSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["compass-diversified"]: {
    primaryShade: 7,
    colors: {
      primary: compassDiversifiedPrimary,
      secondary: compassDiversifiedSecondary,
      background: compassDiversifiedSecondary,
      border: compassDiversifiedSecondary,
      text: slate,
    },
  },
  ["hericanes"]: {
    primaryShade: 6,
    colors: {
      primary: hericansPrimary,
      secondary: hericansSecondary,
      background: slate,
      border: hericansPrimary,
      text: slate,
    },
  },
  ["minds-matter"]: {
    primaryShade: 6,
    colors: {
      primary: mindsMatterPrimary,
      secondary: mindsMatterSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["uc-berkeley---social-sciences"]: {
    primaryShade: 6,
    colors: {
      primary: berkeleyPrimary,
      secondary: berkeleySecondary,
      background: berkeleySecondary,
      border: slate,
      text: slate,
    },
  },
  ["ucla-anderson"]: {
    primaryShade: 8,
    colors: {
      primary: uclaAndersonPrimary,
      secondary: uclaAndersonSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["usd-knauss-school-of-business"]: {
    primaryShade: 6,
    colors: {
      primary: knaussPrimary,
      secondary: knaussSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
};

export const useTheme = () => {
  const partner = useActiveUserPartner();

  const themeToUse =
    schoolThemes[partner?.slug as keyof typeof schoolThemes] || advizeTheme;

  const { colors, ...rest } = themeToUse;

  return createTheme({
    fontFamily: "Arial,Helvetica,sans-serif",
    primaryColor: "blue",
    headings: {
      fontFamily: "League Spartan, sans-serif",
    },
    breakpoints: {
      xs: "36em",
      sm: "48em",
      md: "62em",
      lg: "75em",
      xl: "88em",
      mobileMax: "550px",
      tabletMax: "1100px",
      laptopMax: "1400px",
    },
    colors: {
      slate,
      ...colors,
    },
    ...rest,
  });
};
