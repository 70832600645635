import { Stack, Space, Title, Divider, AppShell, Group } from "@mantine/core";
import {
  useActiveUserPartner,
  useDrawer,
  useIsLoggedIn,
} from "../../store/selectors";
import { Drawer } from "../ui/Drawer";
import { NAV_ITEMS } from "../../configs/navItems";
import { NavLink } from "../ui/NavLink";
import { Link } from "../ui/Link";
import { useStore } from "../../store";
import { config } from "../../config";
import { Analytics } from "../../services/Analytics";
import { Button } from "../ui/Button";

export const drawerId = "mainMenuDrawer";

export function MainMenuDrawer() {
  const { closeDrawer } = useDrawer(drawerId);
  const activeUserPartner = useActiveUserPartner();
  const setActiveFilterPartnerId = useStore(
    (state) => state.advizerSearch.setActiveFilterPartnerId
  );
  const isLoggedIn = useIsLoggedIn();

  return (
    <Drawer id={drawerId} style={{ zIndex: 1000 }} position="left" size="md">
      <AppShell.Navbar p="md" w="100%" bg="background.1">
        <Stack h="100%" justify="space-between" p="md" w="100%">
          <Stack>
            <Stack>
              <Space h={50} />

              <Title order={2} c="text.7" ta="center">
                Welcome to Advize 👋
              </Title>
            </Stack>
            <Space h={4} />
            <Stack gap={0}>
              {NAV_ITEMS.map((navItem, index) => {
                const hide = navItem.hide?.(activeUserPartner?.id);
                if (hide) return null;

                if (!navItem.label) {
                  return <Divider key={index} mt="md" mb="sm" />;
                }

                return (
                  <NavLink
                    key={index}
                    to={navItem.href}
                    onClick={(event) => {
                      if (navItem.label === "Alum Advizers") {
                        setActiveFilterPartnerId(activeUserPartner?.id);
                      } else if (navItem.label === "Explore Advizers") {
                        setActiveFilterPartnerId(null);
                      }

                      Analytics.click({
                        action: `Menu Drawer > ${navItem.label}`,
                        event,
                      });

                      if (navItem.dontClose) {
                        return;
                      }
                      closeDrawer();
                    }}
                    Icon={navItem.Icon}
                    CustomIcon={navItem.CustomIcon?.(activeUserPartner?.id)}
                    linkProps={{ target: navItem.target }}
                  >
                    {navItem.label}
                  </NavLink>
                );
              })}
            </Stack>
            {!isLoggedIn && (
              <Link
                to="/login"
                onClick={(event) => {
                  Analytics.click({
                    action: "Go to sign in from drawer",
                    event,
                  });
                  closeDrawer();
                }}
              >
                <Button fullWidth radius="xl" size="lg" variant="light">
                  Login / Sign up
                </Button>
              </Link>
            )}
          </Stack>
          <Group gap="xs">
            {[
              {
                label: "Terms",
                href: config.termsUrl,
                onClick: () => {
                  Analytics.click({
                    action: "Main Drawer > Terms",
                  });
                },
              },
              {
                label: "Privacy",
                href: config.privacyUrl,
                onClick: () => {
                  Analytics.click({
                    action: "Main Drawer > Privacy",
                  });
                },
              },
            ].map((item) => {
              return (
                <Link
                  to={item.href}
                  key={item.label}
                  target="_blank"
                  style={{ fontSize: 12 }}
                  onClick={item.onClick}
                >
                  {item.label}
                </Link>
              );
            })}
          </Group>
        </Stack>
      </AppShell.Navbar>
    </Drawer>
  );
}
