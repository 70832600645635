import { useStore } from "../store";

class ExperienceClass {
  constructor() {}

  get store() {
    return useStore.getState();
  }

  showConfetti() {
    this.store.experience.setShowConfetti(true);
  }

  showDialog(dialogId: string) {
    this.store.dialog.setActiveDialog(dialogId);
  }

  showWelcomeDialog() {
    this.showDialog("welcome");
  }
}

export const Experience = new ExperienceClass();
