import { config } from "../config";
import { ANALYTICS_EVENTS } from "../configs/analyticsEvents";
import { IAdvizerVideo } from "../models/AdvizerVideo";
import { User } from "../models/User";
import { useStore } from "../store";
import { Firestore } from "./Firestore";
import mixpanel from "mixpanel-browser";

export type IAnalyticsEventData = {
  event?: string;
  action?: string;
  label?: string;
  // custom dimensions
  userAdvizeId?: string;
  userEmail?: string;
  userEmailDomain?: string;
  timestamp?: number;
  email?: string;
  inputValue?: string;
  error?: string;
  filterType?: string;
  filterValue?: string;
  resourceId?: string;
  context?: string;
  page?: string;
  url?: string;

  partner?: string;
  advizerId?: string;
  advizerName?: string;

  // video dimensions
  videoId?: string;
  questionNumber?: number;
  videoTitle?: string;
  videoWatchedSeconds?: number;
  videoDurationSeconds?: number;
  videoWatchedPercent?: number;
  videoSeekFromSeconds?: number;
  videoSeekToSeconds?: number;
  videoHasSeeked?: boolean;
};

declare function gtag(
  command: string,
  event: string,
  data?: IAnalyticsEventData
): void;

const debug = true;
class AnalyticsService {
  trackEvent(event: keyof typeof ANALYTICS_EVENTS, data?: IAnalyticsEventData) {
    try {
      const { activeUser: activeUserRaw } = useStore.getState().user;
      const activeUser = activeUserRaw ? new User(activeUserRaw) : null;
      const eventConfig = ANALYTICS_EVENTS[event];

      if (!eventConfig) {
        console.error(`Event config not found for ${event}`);
        return;
      }

      const doNotTrack =
        activeUser?.email &&
        (activeUser?.rejectedCookies || !activeUser?.acceptedCookies) &&
        !eventConfig?.anonymous;

      if (doNotTrack) {
        console.log("DO NOT TRACK COOKIES");
        return;
      }

      const emailsToIgnore = [
        "yosttest",
        "ryost217",
        "rjyost",
        "ryanjyost",
        "emilymcsherry",
      ];

      let eventParams: IAnalyticsEventData = {
        event,
        url: window.location.pathname,
        timestamp: Date.now(),
      };

      if (activeUser && !eventConfig?.anonymous) {
        eventParams.userAdvizeId = activeUser.uid;
        eventParams.userEmail = activeUser.email;
        eventParams.userEmailDomain = activeUser.email.split("@")[1];
      }

      if (data) {
        eventParams = { ...eventParams, ...data };
      }

      if (emailsToIgnore.some((email) => activeUser?.email?.includes(email))) {
        console.log("EXCLUDED EVENT EMAIL: ", event, eventParams);
        return;
      }

      if (debug || config.isProduction) {
        console.log("EVENT: ", event, eventParams);
      }

      if (config.isProduction) {
        try {
          if (!eventConfig.excludeFromFirestore) {
            Firestore.saveEvent({ event, data: eventParams });
          }
        } catch (e) {
          console.error("Error saving event to Firestore", e);
        }

        try {
          gtag("event", event, eventParams);
        } catch (e) {
          console.error("Error sending event to Google Analytics", e);
        }

        try {
          mixpanel.track(eventConfig?.title || event, eventParams);
        } catch (e) {
          console.error("Error sending event to Mixpanel", e);
        }
      }
    } catch (e) {
      console.error(`Error tracking event: ${event}`, e);
    }
  }

  click({ action, event }: { action: string; event?: any }) {
    // event is native event here, not string
    this.trackEvent("click", {
      action,
      label: event?.target?.innerText || event?.target?.textContent || null,
    });
  }

  invalidEmail(data: IAnalyticsEventData) {
    this.trackEvent("invalid_email", { ...data });
  }

  loginAttempt(data: IAnalyticsEventData) {
    this.trackEvent("login_attempt", { ...data });
  }

  login(data: IAnalyticsEventData) {
    this.trackEvent("login", { ...data });
  }

  logout() {
    this.trackEvent("logout");
  }

  register(data: IAnalyticsEventData) {
    this.trackEvent("register", { ...data });
  }

  error(data: IAnalyticsEventData) {
    this.trackEvent("error", data);
  }

  filterAdvizers(data: IAnalyticsEventData) {
    this.trackEvent("filter_advizers", { context: "Advizer Search", ...data });
  }

  noAdvizersFound(data: IAnalyticsEventData) {
    this.trackEvent("no_advizers_found", { ...data });
  }
  startVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    console.log({ video });
    this.trackEvent("video_start", { ...this.getVideoParams(video), ...rest });
  }
  videoProgress({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_progress", {
      ...this.getVideoParams(video),
      ...rest,
    });
  }
  pauseVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_pause", { ...this.getVideoParams(video), ...rest });
  }

  seekVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_seek", { ...this.getVideoParams(video), ...rest });
  }

  stopVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_stop", { ...this.getVideoParams(video), ...rest });
  }

  acceptCookies() {
    this.trackEvent("accept_cookies");
  }

  rejectCookies() {
    this.trackEvent("reject_cookies");
  }

  private getVideoParams(video: IAdvizerVideo) {
    return {
      videoId: video.id,
      partner: video.advizer?.partners
        .map((partner) => partner.title)
        .join("|"),
      advizerId: video.advizer?.id,
      advizerName: video.advizer?.name,
      questionNumber: video.questionNumber,
      videoTitle: video.title,
    };
  }
}

export const Analytics = new AnalyticsService();
