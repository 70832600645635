import { User as FirebaseUser } from "firebase/auth";

export class User {
  uid: string;
  email: string;
  displayName: string;
  acceptedCookies: boolean;
  rejectedCookies: boolean;
  constructor(
    user: FirebaseUser & {
      acceptedCookies?: boolean;
      rejectedCookies?: boolean;
    }
  ) {
    this.uid = user.uid;
    this.email = user.email || "";
    this.displayName = user.displayName || "";
    this.acceptedCookies = user.acceptedCookies || false;
    this.rejectedCookies = user.rejectedCookies || false;
  }

  get id() {
    return this.uid;
  }
}
