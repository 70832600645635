
export function DataListeners() {
  // const updateActiveUser = useStore((state) => state.user.updateActiveUser);
  // const activeUser = useActiveUser();
  // const { activeDialogId, openDialog: openCookieDialog } =
  //   useDialog("cookieConsent");
  // const [didSubscribeToUser, setDidSubscribeToUser] = useState(false);

  // useEffect(() => {
  //   if (!activeUser || didSubscribeToUser) return;

  //   setDidSubscribeToUser(true);

  //   RealtimeDatabase.subscribeToUser(activeUser.id, (userData: any) => {
  //     console.log("userData", userData);
  //     updateActiveUser({
  //       acceptedCookies: userData?.acceptedCookies || false,
  //       rejectedCookies: userData?.rejectedCookies || false,
  //     });

  //     if (
  //       !userData?.acceptedCookies &&
  //       !userData?.rejectedCookies &&
  //       !activeDialogId
  //     ) {
  //       openCookieDialog();
  //     }

  //     if (userData?.rejectedCookies) {
  //       console.log("OPTING OUT OF MIXPANEL");
  //       mixpanel.opt_out_tracking();
  //     }
  //   });
  // }, [
  //   activeUser,
  //   didSubscribeToUser,
  //   openCookieDialog,
  //   updateActiveUser,
  //   activeDialogId,
  // ]);

  return null;
}
