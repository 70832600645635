import { Stack, Title, Card, Space, Group, Box, Text } from "@mantine/core";
import { useBreakpoints } from "../styles/useStyles";
import logo from "../assets/advize-logo.webp";
import { Link } from "react-router-dom";
import { PartnerLogo } from "./PartnerLogo";
import { useCallback, useMemo } from "react";
import { AdvizerTag } from "./AdvizerTag";
import classes from "../styles/Carousel.module.css";
import { IAdvizerModel } from "../models/Advizer";
import { AdvizerVideoSlideshow } from "./AdvizerVideoSlideshow";
import { Image } from "./ui/Image";
import { useArrayOfSelectedAdvizerSearchFilters } from "../store/selectors";

export function AdvizerVideoCard({
  loading,
  advizerId,
  isScrolling,
  advizer,
}: {
  id?: string;
  loading?: boolean;
  advizerId: string;
  isScrolling?: boolean;
  style?: React.CSSProperties;
  advizer?: IAdvizerModel;
  maxWidth?: number;
}) {
  const { isDesktop, isLaptopOrBigger, isLaptop, isMobile } = useBreakpoints();
  const selectedAdvizerSearchFilters = useArrayOfSelectedAdvizerSearchFilters();

  // const maxVideoWidth = isDesktop ? 550 : isLaptopOrBigger ? 450 : undefined;

  const maxNumTags = isDesktop ? 12 : 8;
  const sortedTags = useMemo(() => {
    if (
      selectedAdvizerSearchFilters &&
      (advizer?.tags?.length || 0) > maxNumTags
    ) {
      return (advizer?.tags || []).sort((a) =>
        // selectedAdvizerSearchFilters.includes(a.id) ? -1 : 1
        selectedAdvizerSearchFilters.some((filter) => filter.id === a.id)
          ? -1
          : 1
      );
    }
    return advizer?.tags.slice(0, maxNumTags) || [];
  }, [advizer?.tags, maxNumTags, selectedAdvizerSearchFilters]);

  const renderAdvizerNameHeader = useCallback(() => {
    return (
      <Group>
        <Link to={`/advizers/${advizerId}`} style={{ flex: 1 }}>
          <Group gap={8} align="center" justify="flex-start">
            <Box
              bg="primary.9"
              p={1}
              bd="1px solid primary.1"
              style={{ borderRadius: "50%" }}
            >
              {isScrolling ? (
                <Box w={40} h={40} bg="primary.5" />
              ) : (
                <Image
                  width={40}
                  height={40}
                  radius="xl"
                  src={advizer?.profilePictureURL || logo}
                />
              )}
            </Box>
            <Title fz={16} order={5} c="text.7" fw={600} lh={1} pt={1}>
              {advizer?.firstName}
            </Title>

            <Text size="sm" c="text.4" fw={500}>
              &#x2022;
            </Text>

            <Text size="sm" c="text.4" fw={500}>
              {advizer?.interviewedTimeAgo}
            </Text>
          </Group>
        </Link>

        <Group justify="flex-end" px="xs">
          {advizer?.partners.map((partner) => (
            <PartnerLogo
              key={partner.id}
              partnerId={partner.id}
              src={partner.iconURL}
              width={40}
            />
          ))}
        </Group>
      </Group>
    );
  }, [
    advizer?.firstName,
    advizer?.interviewedTimeAgo,
    advizer?.partners,
    advizer?.profilePictureURL,
    advizerId,
    isScrolling,
  ]);

  const renderVideos = useCallback(() => {
    if (!advizer) {
      return null;
    }
    return (
      <Card
        padding="md"
        radius="md"
        bg="transparent"
        w="100%"
        h="100%"
        flex={1}
      >
        <Card.Section pos="relative">
          <AdvizerVideoSlideshow classes={classes} advizer={advizer} />
        </Card.Section>
      </Card>
    );
  }, [advizer]);

  const renderAdvizerTitle = useCallback(() => {
    return (
      <Link to={`/advizers/${advizerId}`} style={{ width: "100%" }}>
        <Title
          fz={isLaptop ? 24 : isMobile ? 28 : 32}
          c="text.7"
          w="100%"
          fw={600}
          lh={1.2}
          py={2}
          px={4}
          ta="left"
          lineClamp={3}
        >
          <span style={{ fontWeight: 700 }}>{advizer?.jobTitle}</span>
          <span style={{ paddingLeft: 6, paddingRight: 8, opacity: 0.8 }}>
            @
          </span>
          <span style={{ opacity: 0.8 }}>{advizer?.company}</span>
        </Title>
      </Link>
    );
  }, [advizer?.company, advizer?.jobTitle, advizerId, isLaptop, isMobile]);

  const renderTags = useCallback(() => {
    return (
      <Stack gap="xs" w="100%">
        <Group w="100%" gap={isDesktop ? 12 : 8} px={0} py="xs">
          {sortedTags.map((tag) => (
            <AdvizerTag key={tag.id} tag={tag} size="lg" maw={300} />
          ))}
        </Group>
      </Stack>
    );
  }, [isDesktop, sortedTags]);

  const renderContent = useCallback(() => {
    if (isLaptopOrBigger) {
      return (
        <Group align="start" justify="center" w="100%" p="md" wrap="nowrap">
          <Stack flex={1} gap={8} pt={4} w="100%">
            {renderAdvizerNameHeader()}
            <Space h={0} />
            {renderAdvizerTitle()}
            {renderTags()}
          </Stack>
          <Stack flex={1}>{renderVideos()}</Stack>
        </Group>
      );
    }
    return (
      <Stack w="100%" h="100%" maw={700} gap={0}>
        <Group w="100%">
          <Stack w="100%" ta="left" p={0} gap={0} h="100%">
            {renderAdvizerNameHeader()}
            <Space h="xs" />
            {renderVideos()}
          </Stack>
        </Group>

        <Space h={12} />

        {renderAdvizerTitle()}

        {renderTags()}
      </Stack>
    );
  }, [
    isLaptopOrBigger,
    renderAdvizerNameHeader,
    renderVideos,
    renderAdvizerTitle,
    renderTags,
  ]);

  if (!advizer?.name && !loading) {
    return null;
  }

  // TODO auto pause/play if video is playing and user goes to next/previous
  return (
    <Stack align="center" w="100%" mx="auto" gap={0} py="xs" px="xs">
      {renderContent()}
    </Stack>
  );
}
