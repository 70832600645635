import BarLoader from "react-spinners/BarLoader";
import { useBreakpoints, useStyles } from "../styles/useStyles";
import {
  useAdvizerSearchText,
  useArrayOfSelectedAdvizerSearchFilters,
  useDrawer,
  useFilteredAdvizersCount,
  useIsAdvizersFiltered,
  useIsSearchingAdvizers,
  useQuestions,
} from "../store/selectors";
import {
  AdvizerSearchDrawer,
  drawerId as filtersDrawerId,
} from "./drawers/AdvizerSearchDrawer";
import { Stack, Group, Select, Box, Space, Text } from "@mantine/core";
import { IconSearch, IconAdjustments, IconPencil } from "@tabler/icons-react";
import { useIsAdvizerProfilePage, useStore } from "../hooks";
import { useCallback } from "react";
import { TextInput } from "./ui/TextInput";
import { AdvizerTag } from "./AdvizerTag";
import { Button } from "./ui/Button";
import { Analytics } from "../services/Analytics";

export const AdvizerSearchControls = () => {
  const { theme, ...styles } = useStyles();
  const isAdvizerProfilePage = useIsAdvizerProfilePage();
  const questions = useQuestions();
  const { openDrawer } = useDrawer(filtersDrawerId);
  const { isTablet, isDesktop, isLaptopOrBigger, isMobile } = useBreakpoints();
  const filteredAdvizersCount = useFilteredAdvizersCount();
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader
  );

  const resetAdvizerSearch = useStore(
    (state) => state.advizerSearch.resetAdvizerSearch
  );

  const { searchText, setSearchText } = useAdvizerSearchText();

  const { isSearchingAdvizers } = useIsSearchingAdvizers();

  const arrayOfSelectedAdvizerSearchFilters =
    useArrayOfSelectedAdvizerSearchFilters();

  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId
  );

  const setActiveQuestionId = useStore(
    (state) => state.advizerSearch.setActiveQuestionId
  );

  const activeFilterPartnerId = useStore(
    (state) => state.advizerSearch.activeFilterPartnerId
  );

  const isAdvizersFiltered = useIsAdvizersFiltered();

  const renderSearchBar = useCallback(() => {
    if (isAdvizerProfilePage) {
      return null;
    }

    return (
      <TextInput
        value={searchText || ""}
        onClear={() => setSearchText("")}
        onDebouncedChange={(value) => {
          setSearchText(value);
          Analytics.filterAdvizers({
            filterType: "search",
            filterValue: value,
          });
        }}
        placeholder="Type to search Advizers"
        radius="sm"
        flex={1}
        leftSection={<IconSearch size={18} color={theme.colors.text[4]} />}
        variant={isLaptopOrBigger ? "default" : "default"}
        size="sm"
      />
    );
  }, [
    isAdvizerProfilePage,
    isLaptopOrBigger,
    searchText,
    setSearchText,
    theme.colors.text,
  ]);

  const renderTotalVisibleAdvizers = useCallback(() => {
    return (
      <Text c="text.5" size="md">
        <span style={{ fontWeight: 700 }}>{filteredAdvizersCount}</span>{" "}
        {!activeFilterPartnerId ? (
          <span>{filteredAdvizersCount === 1 ? "Advizer" : "Advizers"}</span>
        ) : (
          <span>{filteredAdvizersCount === 1 ? "Alum" : "Alums"}</span>
        )}
      </Text>
    );
  }, [filteredAdvizersCount, activeFilterPartnerId]);

  const renderQuestionSelector = useCallback(() => {
    return (
      <Select
        flex={1}
        clearable
        placeholder="Click to focus on a question"
        variant={isLaptopOrBigger ? "default" : "default"}
        size={"sm"}
        value={activeQuestionId || null}
        onChange={(value) => {
          if (value) {
            const question = questions.find((q) => q.id === value);
            Analytics.filterAdvizers({
              filterType: "question",
              filterValue: question
                ? `Q${question?.number}: ${question?.title}`
                : value,
              resourceId: value,
            });
          }

          setActiveQuestionId(value || null);
        }}
        data={[
          {
            value: "",
            label: "All Questions",
          },
          ...questions.map((question: any) => ({
            value: question.id,
            label: `Q${question.number}: ${question.title}`,
          })),
        ]}
      />
    );
  }, [activeQuestionId, isLaptopOrBigger, questions, setActiveQuestionId]);

  const renderResetButton = useCallback(() => {
    if (!isAdvizersFiltered) return null;
    return (
      <Button
        variant="transparent"
        size={isMobile ? "xs" : "sm"}
        disabled={!searchText && !arrayOfSelectedAdvizerSearchFilters.length}
        onClick={resetAdvizerSearch}
      >
        Reset
      </Button>
    );
  }, [isAdvizersFiltered, isMobile, searchText, arrayOfSelectedAdvizerSearchFilters.length, resetAdvizerSearch]);

  const renderFiltersButton = useCallback(() => {
    return (
      <Button
        variant={isAdvizersFiltered ? "light" : "light"}
        leftSection={
          isAdvizersFiltered ? (
            <IconPencil size={18} />
          ) : (
            <IconAdjustments size={18} />
          )
        }
        radius="sm"
        size="sm"
        px="xs"
        onClick={() => {
          openDrawer();
        }}
      >
        {isAdvizersFiltered ? "Adjust" : "Explore"}
      </Button>
    );
  }, [isAdvizersFiltered, openDrawer]);

  const renderSelectedTags = useCallback(() => {
    return (
      <>
        {arrayOfSelectedAdvizerSearchFilters
          .sort((a, b) => {
            return a.title > b.title ? 1 : -1;
          })
          .map((tag) => (
            <AdvizerTag key={tag.id} tag={tag} size="md" />
          ))}
      </>
    );
  }, [arrayOfSelectedAdvizerSearchFilters]);

  const renderLoadingBar = useCallback(() => {
    if (!isSearchingAdvizers) return null;
    return (
      <BarLoader
        style={{ width: "100%" }}
        color={theme.colors.primary[4]}
        height={2}
      />
    );
  }, [isSearchingAdvizers, theme.colors.primary]);

  const renderContent = useCallback(() => {
    if (isAdvizerProfilePage) {
      if (isLaptopOrBigger) {
        return (
          <>
            <Group
              p={0}
              justify="space-between"
              wrap="nowrap"
              align="flex-start"
            >
              <Group gap="sm">
                <Box w={300}>{renderQuestionSelector()}</Box>
                {renderTotalVisibleAdvizers()}
                {renderSelectedTags()}
              </Group>
              <Group>{renderFiltersButton()}</Group>
            </Group>
          </>
        );
      }

      return (
        <>
          <Stack
            gap={arrayOfSelectedAdvizerSearchFilters.length > 0 ? "xs" : 0}
          >
            <Group
              p={0}
              justify="space-between"
              wrap="nowrap"
              align="flex-start"
            >
              <Group gap="sm" flex={1}>
                {renderQuestionSelector()}
                {renderTotalVisibleAdvizers()}
              </Group>
              <Group>{renderFiltersButton()}</Group>
            </Group>
            <Group gap="xs">
              {renderSelectedTags()} {renderResetButton()}
            </Group>
          </Stack>
        </>
      );
    }

    if (isLaptopOrBigger) {
      return (
        <>
          <Group justify="space-between" px="sm">
            <Group flex={1} justify="flex-start" gap={"xs"}>
              <Box flex={1} maw={300} miw={200}>
                {renderSearchBar()}
              </Box>
              <Box flex={1} maw={300} miw={200}>
                {renderQuestionSelector()}
              </Box>
              {renderTotalVisibleAdvizers()}
              <Group align="center">{renderSelectedTags()}</Group>
              {renderResetButton()}
            </Group>
          </Group>
          <AdvizerSearchDrawer />
        </>
      );
    }

    if (isTablet) {
      return (
        <>
          <Group>
            <Box flex={1}>{renderSearchBar()}</Box>
            <Box flex={1}>{renderQuestionSelector()}</Box>
            {renderTotalVisibleAdvizers()}
            {renderFiltersButton()}
          </Group>
          <AdvizerSearchDrawer />
        </>
      );
    }

    return (
      <Stack gap="xs">
        <Group w="100%">
          {renderSearchBar()}
          {renderTotalVisibleAdvizers()}
        </Group>
        <Group p={0} gap="xs">
          {renderQuestionSelector()}
          {renderFiltersButton()}
          {renderResetButton()}
        </Group>

        <AdvizerSearchDrawer />
      </Stack>
    );
  }, [
    arrayOfSelectedAdvizerSearchFilters.length,
    isAdvizerProfilePage,
    isLaptopOrBigger,
    isTablet,
    renderFiltersButton,
    renderQuestionSelector,
    renderResetButton,
    renderSearchBar,
    renderSelectedTags,
    renderTotalVisibleAdvizers,
  ]);

  return (
    <Stack
      px="xs"
      pt="xs"
      gap={0}
      pos="sticky"
      top={collapseHeader ? 0 : styles.dimensions.headerHeight}
      // maw={1200}
      style={{
        zIndex: 10,
        borderBottom: `1px solid ${theme.colors.border[2]}`,
      }}
      bg={isDesktop ? "background.1" : "background.1"}
    >
      {renderContent()}
      <Space h="xs" />
      {renderLoadingBar()}
    </Stack>
  );
};
