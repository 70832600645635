import { onValue, ref, update, off } from "firebase/database";
import { db } from "../firebase/firebase";
import { useStore } from "../store";
import { User } from "../models/User";

class RealtimeDatabaseService {
  get db() {
    return db;
  }

  updateCurrentUser(data: any) {
    const activeUser = new User(useStore.getState().user?.activeUser);
    if (!activeUser) return;

    const userRef = ref(this.db, `users/${activeUser.id}`);
    update(userRef, data);
  }

  subscribeToUser(userId: string, callback: (user: User) => void) {
    const userRef = ref(this.db, `users/${userId}`);
    off(userRef, "value");

    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      callback(userData);
    }, (error) => {
      console.error("Error subscribing to user", error);
    });
  }
}

export const RealtimeDatabase = new RealtimeDatabaseService();
