import { Group, Stack, Text, Title } from "@mantine/core";
import { useChipTooltipSlice, useDrawer } from "../../store/selectors";
import { Drawer } from "../ui/Drawer";
import { IconInfoCircle } from "@tabler/icons-react";
import { useStyles } from "../../styles/useStyles";
import { Button } from "../ui/Button";

const drawerId = "tooltipDrawer";
export const TooltipDrawer = () => {
  const { theme } = useStyles();
  const { activeTooltipData } = useChipTooltipSlice();
  const { closeDrawer } = useDrawer(drawerId);

  return (
    <Drawer
      id={drawerId}
      position="bottom"
      size="md"
      title={
        <Group align="center">
          <IconInfoCircle size={24} color={theme.colors.primary[4]} />
          <Title order={4} lh={1.2} flex={1} c="text.5" mt={2}>
            Learn more about...{" "}
          </Title>
        </Group>
      }
      withCloseButton={false}
    >
      <Stack
        gap={0}
        flex={1}
        justify="space-between"
        style={{
          overflow: "scroll",
          maxHeight: "100%",
          flex: 1,
          borderTop: `1px solid ${theme.colors.border[2]}`,
        }}
      >
        <Stack p="md" gap="xs" pb={100}>
          <Title order={2} c="text.7">
            {activeTooltipData?.title}
          </Title>
          <Text>
            {activeTooltipData?.description ||
              "The marketing industry encompasses the strategies and creative processes used to promote products or services to consumers. It connects businesses with their target audiences through various channels, including digital media, social networking, and advertising. Marketing professionals aim to build brand awareness, generate leads, and drive sales by influencing consumer behavior. The industry's constantly evolving nature, driven by technological advancements and changing consumer preferences, makes it a dynamic and challenging field."}
          </Text>
        </Stack>
        <Group
          p="md"
          pos="absolute"
          bottom={0}
          w="100%"
          bg="background.0"
          style={{ borderTop: `1px solid ${theme.colors.border[2]}` }}
        >
          <Button flex={1} variant="light" color="background" onClick={closeDrawer}>
            Close
          </Button>
          <Button flex={1} onClick={closeDrawer}>
            Filter Advizers
          </Button>
        </Group>
      </Stack>
    </Drawer>
  );
};
