import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { useCloseActiveDrawer } from "../../store/selectors";

export interface LinkProps extends RouterLinkProps {
  disabled?: boolean;
  radius?: string | number;
}

export const Link = (props: LinkProps) => {
  const { to, children, disabled, onClick, radius, ...rest } = props;
  const closeActiveDrawer = useCloseActiveDrawer();

  if (disabled) {
    return <span>{children}</span>;
  }

  return (
    <RouterLink
      to={to}
      style={{
        pointerEvents: disabled ? "none" : undefined,
        borderRadius: radius,
      }}
      onClick={(e) => {
        closeActiveDrawer();
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};
