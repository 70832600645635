import { Group, Loader, Stack, Title } from "@mantine/core";
import { FixedSizeList } from "react-window";
import { AdvizerVideoCard } from "../components/AdvizerVideoCard";
import { AdvizerSearchControls } from "../components/AdvizerSearchControls";
import { useBreakpoints, useStyles } from "../styles/useStyles";
import { useFilteredAdvizers } from "../store/selectors";
import { AdvizerSearchFilters } from "../components/AdvizerSearchFilters";
import { useCallback } from "react";
import { AutoSizer } from "react-virtualized";
import { useStore, useValueFromBreakpoint } from "../hooks";
import { ErrorOrNoResults } from "../components/templates/ErrorOrNoResults";

// TODO use virtualized list
export const AdvizerSearchPage = () => {
  const { theme, ...styles } = useStyles();
  const { isLaptopOrBigger } = useBreakpoints();
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader
  );
  const setCollapseHeader = useStore(
    (state) => state.advizerSearch.setCollapseHeader
  );
  const { advizers, isAdvizersLoading } = useFilteredAdvizers();

  const advizerVideoCardHeight = useValueFromBreakpoint({
    mobile: 680,
    tablet: 720,
    laptop: 450,
    desktop: 500,
  });

  const maxVideoCardWidth = useValueFromBreakpoint({
    mobile: 500,
    tablet: 650,
    laptop: 900,
    desktop: 1200,
  });

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const advizer = advizers?.[index];
      if (!advizer) return null;

      return (
        <Group
          id={String(index)}
          align="start"
          justify="center"
          key={advizer.id}
          pt={0}
          w="100%"
          style={{
            ...style,
            borderBottom: `1px solid ${theme.colors.text[2]}`,
            overflow: "hidden",
          }}
          gap={0}
        >
          <AdvizerVideoCard
            advizerId={advizer.id}
            advizer={advizer}
            maxWidth={maxVideoCardWidth}
            // isScrolling={params.isScrolling}
          />
        </Group>
      );
    },
    [advizers, maxVideoCardWidth, theme.colors.text]
  );

  const renderAdvizers = useCallback(() => {
    if (isAdvizersLoading) {
      return (
        <Stack mih={400} align="center" justify="start" pt="xl">
          <Loader color="primary.5" size="xl" />
          <Title order={3} c="text.5">
            Loading Advizers...
          </Title>
        </Stack>
      );
    }

    if (!advizers?.length) {
      return <ErrorOrNoResults />;
    }

    // TODO get tallest one in list, just force row height and card height to be same
    return (
      <Stack flex={1} w="100%">
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              width={width}
              height={height}
              itemCount={advizers?.length || 0}
              itemSize={advizerVideoCardHeight}
              onScroll={(props) => {
                if (
                  props.scrollDirection === "forward" &&
                  props.scrollOffset > styles.dimensions.headerHeight
                ) {
                  setCollapseHeader(true);
                } else {
                  setCollapseHeader(false);
                }
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Stack>
    );
  }, [
    advizerVideoCardHeight,
    advizers?.length,
    isAdvizersLoading,
    rowRenderer,
    setCollapseHeader,
    styles.dimensions.headerHeight,
  ]);

  return (
    <Group
      pt={collapseHeader ? 0 : styles.dimensions.headerHeight}
      gap={0}
      align="start"
      h="100vh"
      mah="100vh"
    >
      <Stack p={0} gap={0} flex={2} mah="100vh">
        <AdvizerSearchControls />
        <Stack align="center" w="100%" mih={"100vh"} gap="lg" bg="background.0">
          {renderAdvizers()}
        </Stack>
      </Stack>
      {isLaptopOrBigger ? (
        <Stack
          h={`calc(100vh - ${
            collapseHeader ? 0 : styles.dimensions.headerHeight
          }px)`}
          maw={400}
          flex={1}
          pos="sticky"
          top={collapseHeader ? 0 : styles.dimensions.headerHeight - 10}
          style={{ zIndex: 10 }}
          gap={0}
          p={0}
        >
          <AdvizerSearchFilters />
        </Stack>
      ) : null}
    </Group>
  );
};
