import { IAdvizerModel } from "../models/Advizer";
import { isObjectEmpty } from "./helpers";

// const IS_AND_FOR_DIFF_FILTERS = true;

export function searchAdvizers(params: {
  advizers: IAdvizerModel[];
  searchText: string;
  selectedSubjects: Record<string, boolean>;
  selectedIndustries: Record<string, boolean>;
  selectedJobFunctions: Record<string, boolean>;
  selectedTraits: Record<string, boolean>;
  selectedCompanies: Record<string, boolean>;
  partnerId: string | null;
}) {
  // return [];
  const {
    advizers,
    searchText,
    selectedSubjects = {},
    selectedIndustries = {},
    selectedJobFunctions = {},
    selectedTraits = {},
    selectedCompanies = {},
    partnerId,
  } = params;

  let advizerPool = advizers;

  if (partnerId) {
    advizerPool = advizerPool.filter((advizer) =>
      advizer.partners.some((partner) => partner.id === partnerId)
    );
  }

  if (
    !searchText &&
    [
      selectedSubjects,
      selectedIndustries,
      selectedJobFunctions,
      selectedTraits,
      selectedCompanies,
    ].every(isObjectEmpty)
  )
    return advizerPool.filter((advizer) => advizer?.name);

  return advizerPool.filter((advizer) => {
    if (!advizer?.name) return false;

    const isSearchTextMatch = advizer.searchText
      .toLowerCase()
      .includes(searchText.toLowerCase());

    if (searchText.length > 0 && !isSearchTextMatch) return false;

    // const isAndFilter = IS_AND_FOR_DIFF_FILTERS;
    const hasIndustries = Object.keys(selectedIndustries).length > 0;
    const isIndustryMatch = hasIndustries
      ? advizer.industries.some((industry) => selectedIndustries[industry.id])
      : true;

    const hasSubjects = Object.keys(selectedSubjects).length > 0;
    const isSubjectMatch = hasSubjects
      ? advizer.subjects.some((subject) => selectedSubjects[subject.id])
      : true;

    const hasJobFunctions = Object.keys(selectedJobFunctions).length > 0;
    const isJobFunctionMatch = hasJobFunctions
      ? advizer.jobFunctions.some(
          (jobFunction) => selectedJobFunctions[jobFunction.id]
        )
      : true;

    const hasTraits = Object.keys(selectedTraits).length > 0;
    const isTraitMatch = hasTraits
      ? advizer.traits.some((trait) => selectedTraits[trait.id])
      : true;

    const hasCompanies = Object.keys(selectedCompanies).length > 0;
    const isCompanyMatch = hasCompanies
      ? Object.keys(selectedCompanies).some(
          (companyName) =>
            advizer.company.toLowerCase().includes(companyName.toLowerCase()) ||
            advizer.company.toLowerCase().includes(companyName.toLowerCase())
        )
      : true;

    return (
      isSearchTextMatch &&
      isIndustryMatch &&
      isSubjectMatch &&
      isJobFunctionMatch &&
      isTraitMatch &&
      isCompanyMatch
    );
    if (Object.keys(selectedIndustries).length > 0) {
      const industryMatch = advizer.industries.some(
        (industry) => selectedIndustries[industry.id]
      );

      if (industryMatch) return true;
    }

    if (Object.keys(selectedSubjects).length > 0) {
      const subjectMatch = advizer.subjects.some(
        (subject) => selectedSubjects[subject.id]
      );

      if (subjectMatch) return true;
    }

    if (Object.keys(selectedJobFunctions).length > 0) {
      const jobFunctionMatch = advizer.jobFunctions.some(
        (jobFunction) => selectedJobFunctions[jobFunction.id]
      );

      if (jobFunctionMatch) return true;
    }

    if (Object.keys(selectedTraits).length > 0) {
      const traitMatch = advizer.traits.some(
        (trait) => selectedTraits[trait.id]
      );

      if (traitMatch) return true;
    }

    if (Object.keys(selectedCompanies).length > 0) {
      const companyMatch = Object.keys(selectedCompanies).some(
        (companyName) =>
          advizer.company.toLowerCase().includes(companyName.toLowerCase()) ||
          advizer.company.toLowerCase().includes(companyName.toLowerCase())
      );
      if (companyMatch) return true;
    }

    return false;
  });
  // .filter((advizer) => advizer.searchText.toLowerCase().includes(searchText));
}
