import { useStore } from "../store";

class NavigationServiceClass {
  navigate(path: string) {
    useStore.getState().navigation.setNavToPath(path);
  }

  navToLogin() {
    this.navigate("/login");
  }
}

export const Navigation = new NavigationServiceClass();
